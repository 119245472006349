import { AxiosError } from "axios"
import { useMessage } from "hooks/message.hook"

export const useApiErrorFormatter = () => {
	const { error: errorMessage } = useMessage()

	const formatError = (e: AxiosError) => {
		const data = e.response?.data
		const status = e.response?.status

		if (status === 400) {
			if (data) {
				Object.entries(data).map(([key, value]) => {
					const transformedKey = key
						.split("_")
						.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
						.join(" ")

					const transformedValue = value[0]
						.split(" ")
						.map((word: string, index: number) => (index === 0 ? word.charAt(0).toLowerCase() + word.slice(1) : word))
						.join(" ")

					errorMessage(`${transformedKey}: ${transformedValue}`)
				})
			}
		}
	}

	return { formatError }
}
