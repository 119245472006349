import { MouseEvent, useState } from "react"
import { QuestionArrowIcon } from "components/ui/icons/iaAgent/QuestionArrowIcon"
import { useAskQuestion, useGetQuestions } from "hooks/AIAgent.hook"
import { useNatsAnswerStream } from "hooks/nats.hook"
import { useChatStore } from "store/aiAgent.store"
import { Spin } from "components/ui/spin/Spin"
import { MDtoHTML } from "components/ui/MDtoHTML/MDtoHTML"
import { useMessage } from "hooks/message.hook"
import { useSearchParams } from "react-router-dom"
import { FeedbackForResponse } from "../FeedbackForResponse/FeedbackForResponse"
import { useViewerStore } from "store/PDFViewer.store"
import style from "./Chat.module.scss"
import { GeneralFeedbackForResponse } from "../GeneralFeedbackForResponse/GeneralFeedbackForResponse"

const {
	chat,
	buble,
	answer,
	question,
	listItem,
	arrow,
	list,
	stickyBlock,
	answerText,
	questionDisabled,
	chatBottomBar,
} = style

const natsUrl = import.meta.env.VITE_NATS_URL

type QuestionsType = { id: number; text: string }

export const Chat = () => {
	const { mutate: askQuestionMutation, isPending: isAskQuestionPending } = useAskQuestion()
	const { error: errorMessage } = useMessage()
	const [searchParams] = useSearchParams()
	const drawingId = Number(searchParams.get("drawingID"))
	const { data: questionsData } = useGetQuestions(drawingId)
	const streamingAnswer = useNatsAnswerStream(natsUrl, questionsData?.nats_subject)

	const { chatHistory, addQuestion } = useChatStore()
	const { navigateToPage } = useViewerStore()

	const [langsmithTraceId, setLangsmithTraceId] = useState()
	const [questionId, setQuestionId] = useState<number>()

	const streamingInProgress = streamingAnswer?.status === "STARTED" || streamingAnswer?.status === "RUNNING"

	const askQuestionHandle = (e: MouseEvent<HTMLElement>, questionId: number, subject: string, question: string) => {
		if (streamingInProgress) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
		addQuestion(question)
		setQuestionId(questionId)

		askQuestionMutation(
			{ drawingId, questionId, subject },
			{
				onSuccess: (response) => {
					setLangsmithTraceId(response.data.langsmith_trace_id)
				},
				onError: () => {
					errorMessage("Something went wrong, please try again later")
				},
			},
		)
	}

	const handleReferenceClick = (page: string) => {
		navigateToPage(page, errorMessage)
	}

	return (
		<div className={chat}>
			<div className={`${buble} ${answer}`}>
				<span>Hello!</span>
			</div>
			<div className={`${buble} ${answer} ${stickyBlock}`}>
				<p>{questionsData?.status_message}</p>
				{/* Show predefined questions when the status is success and there are questions */}
				{questionsData?.status === "success" && questionsData?.questions?.length > 0 ? (
					<>
						<ul className={list}>
							{questionsData?.questions.map((item: QuestionsType) => (
								<li
									className={`${listItem} ${streamingInProgress ? questionDisabled : ""}`}
									key={item.id}
									onClick={(e) => askQuestionHandle(e, item.id, questionsData.nats_subject, item.text)}
								>
									<div className={arrow}>
										<QuestionArrowIcon />
									</div>
									<span>{item.text}</span>
								</li>
							))}
						</ul>
					</>
				) : null}
				{/* Show feedback when the status is not success */}
				{questionsData?.status !== "success" ? (
					<div className={chatBottomBar}>
						<GeneralFeedbackForResponse />
					</div>
				) : null}
			</div>
			{chatHistory.questions.map((item, index) => (
				<div key={`qa-${index}`}>
					<div className={`${buble} ${question}`}>
						<span>{item}</span>
					</div>
					{
						// If the question is not answered yet show the loader
						index === chatHistory.questions.length - 1 &&
						(streamingAnswer?.status === "STARTED" || isAskQuestionPending) ? (
							<Spin size="large" />
						) : (
							chatHistory.answers[index] && (
								<div className={`${buble} ${answer} ${answerText}`}>
									<MDtoHTML content={chatHistory.answers[index]} onReferenceClick={handleReferenceClick} />
									<div className={chatBottomBar}>
										<FeedbackForResponse
											langsmithTraceId={langsmithTraceId}
											drawingId={drawingId}
											questionId={questionId}
										/>
									</div>
								</div>
							)
						)
					}
				</div>
			))}
		</div>
	)
}
