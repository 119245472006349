import { useCallback, useEffect, useState } from "react"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { UploadFileIcon } from "components/ui/icons/button/UploadFileIcon"
import { Upload } from "antd"
import { getMD5Hash } from "helpers/getMD5Hash.helper"
import { RcFile } from "antd/es/upload"
import { useUploadFileHash, useUploadFinished } from "hooks/uploadFiles.hook"
import { useUploadFile } from "hooks/uploadFiles.hook"
import {
	useFilesToAbortStore,
	useUploadFilesDataStore,
	useUploadbarEnabledStore,
	useUploadbarOpenStore,
} from "store/uploadFiles.store"
import { useMessage } from "hooks/message.hook"
import { AxiosError, AxiosProgressEvent } from "axios"
import { Spin } from "components/ui/spin/Spin"
import { useDrawings } from "hooks/drawings.hook"
import { TUploadFinished } from "types/uploadFiles.type"
import style from "./UploadFiles.module.scss"
import { useFillesTableStore } from "store/table.store"

const { button } = style
interface ExtendedRcFile extends RcFile {
	processingUpload?: boolean
}

export const UploadFiles = () => {
	const { setUploadbarEnabled, setUploadbarDisabled } = useUploadbarEnabledStore()
	const { setFilesData, setUpdateFilesData, allFiles, latestBatchIds, resetLatestBatch } = useUploadFilesDataStore()
	const { setFileController, resetIdsToAbort } = useFilesToAbortStore()
	const { setClosed, setOpen } = useUploadbarOpenStore()
	const { setCurrentPage } = useFillesTableStore()

	const { error, success } = useMessage()
	const { refetch: refetchDrawings } = useDrawings()
	const { mutateAsync: uploadFileHash, isPending: fileHashIsPending } = useUploadFileHash()
	const { mutateAsync: uploadFile } = useUploadFile()
	const { mutateAsync: uploadFinished } = useUploadFinished()

	const [isMD5HashIsCalculating, setIsMD5HashIsCalculating] = useState(false)

	const calculateHashAndUpload = async (file: ExtendedRcFile) => {
		setIsMD5HashIsCalculating(true)

		try {
			file.processingUpload = true // Mark file as being processed
			setUpdateFilesData({ id: file.uid, status: "uploading" })

			const hash = await getMD5Hash(file)
			await uploadFileWithData(file, hash)
		} catch (e) {
			// Error handling only for non AxiosErros
			if (!(e instanceof AxiosError)) {
				processUploadError(e, file)
			}
		} finally {
			setIsMD5HashIsCalculating(false)
		}
	}

	const uploadFileWithData = async (file: ExtendedRcFile, hash: string) => {
		const uploadFinishedData: TUploadFinished = {
			uploadId: "",
			result: "pending",
			reason: "",
		}

		try {
			const hashResponse = await uploadFileHash({ hash, fileName: file.name })
			if (!hashResponse?.data) {
				throw new Error("Failed to get upload URL")
			}

			setUploadbarEnabled()
			setOpen()

			const { upload_presigned_url: presignedUrl, uploadId } = hashResponse.data
			uploadFinishedData.uploadId = uploadId

			const controller = new AbortController()
			setFileController(file.uid, controller)

			try {
				await uploadFile({
					presignedUrl,
					file,
					onProgress: (progressEvent: AxiosProgressEvent) => {
						setFilesData({
							id: file.uid,
							name: file.name,
							progress: progressEvent.progress,
							estimated: progressEvent.estimated,
							status: "uploading",
						})
					},
					abortController: controller,
				})

				// Only mark as success if upload completes without being aborted
				uploadFinishedData.result = "success"
				await finalizeUpload(file, uploadFinishedData)
			} catch (uploadError) {
				if (uploadError instanceof AxiosError && uploadError.message === "canceled") {
					uploadFinishedData.result = "canceled"
					setUpdateFilesData({ id: file.uid, status: "canceled" })
					finalizeUpload(file, uploadFinishedData)
					return
				}
				throw uploadError // Re-throw other errors to be handled by outer catch
			}
		} catch (e) {
			processUploadError(e, file, uploadFinishedData)
		}
	}

	const processUploadError = (e: unknown, file: ExtendedRcFile, uploadFinishedData?: TUploadFinished) => {
		if (e instanceof AxiosError) {
			if (e.message === "canceled") {
				if (uploadFinishedData) uploadFinishedData.result = "canceled"
				return
			}

			error(`Upload error for file: ${file.name}. Please, try to upload the file again`)
			if (uploadFinishedData) {
				uploadFinishedData.result = "failure"
				uploadFinishedData.reason = e.message || "Unknown error"
				finalizeUpload(file, uploadFinishedData)
			}
			setUpdateFilesData({ id: file.uid, status: "failed" })
		} else {
			error("Your file may be corrupted, please re-upload")
			setUpdateFilesData({ id: file.uid, status: "failed" })
			if (uploadFinishedData) finalizeUpload(file, uploadFinishedData)
		}
	}

	const finalizeUpload = async (file: ExtendedRcFile, uploadFinishedData: TUploadFinished) => {
		if (!uploadFinishedData.uploadId) return

		try {
			await uploadFinished(uploadFinishedData)
			if (uploadFinishedData.result === "success") {
				setUpdateFilesData({ id: file.uid, status: "uploaded" })
				refetchDrawings()
			}
		} catch (e) {
			setUpdateFilesData({ id: file.uid, status: "failed" })
			error(`Upload error for file: ${file.name}. Please, try to upload the file again`)
		}
	}

	const beforeUploadHandle = async (_: RcFile, fileList: ExtendedRcFile[]) => {
		// const validFiles = fileList.filter((file) => file && file.uid)

		const uploadPromises = fileList.map(async (file) => {
			if (file.processingUpload) return
			await calculateHashAndUpload(file)
		})

		// Await all uploads to complete
		await Promise.all(uploadPromises)

		return false // Prevent default upload behavior
	}

	// Check if all files in the latest batch are completed (uploaded, failed, or canceled)
	const areAllFilesCompleted = useCallback(() => {
		// Filter allFiles to only include those in the latest batch
		const latestBatchFiles = allFiles.filter((file) => latestBatchIds.includes(file.id))

		// If we have no files being tracked or no latest batch, return false
		if (latestBatchFiles.length === 0 || latestBatchIds.length === 0) {
			return false
		}

		// Check if all latest batch files have a completed status
		const allCompleted = latestBatchFiles.every((file) => file.status === "uploaded" || file.status === "canceled")

		// Make sure we've accounted for all files in the batch
		return allCompleted && latestBatchFiles.length === latestBatchIds.length
	}, [allFiles, latestBatchIds])

	// Check if all files in the latest batch are completed
	useEffect(() => {
		if (areAllFilesCompleted()) {
			// Display success message only if at least one file was uploaded successfully
			const hasSuccessfulUploads = allFiles.some(
				(file) => latestBatchIds.includes(file.id) && file.status === "uploaded",
			)

			// Check if there only one cancelled file
			const hasOnlyOneCancelledFile = allFiles.some(
				(file) => latestBatchIds.includes(file.id) && file.status === "canceled" && latestBatchIds.length > 1,
			)

			if (hasOnlyOneCancelledFile) {
				setCurrentPage(1)
			}

			if (hasSuccessfulUploads) {
				success("Upload successful")
			}

			setClosed() // Close uploadbar
			resetIdsToAbort() // Clear all abort IDs
			resetLatestBatch() // Clear latest batch
		}
	}, [
		latestBatchIds,
		setClosed,
		setUploadbarDisabled,
		resetIdsToAbort,
		success,
		areAllFilesCompleted,
		allFiles,
		resetLatestBatch,
		setCurrentPage,
	])

	return (
		<Upload
			accept=".pdf"
			name="upload"
			beforeUpload={beforeUploadHandle}
			showUploadList={false}
			data-testid="uploadFiles"
			multiple
		>
			{fileHashIsPending || isMD5HashIsCalculating === true ? (
				<Spin />
			) : (
				<ButtonPrimary className={button}>
					<UploadFileIcon />
					Upload files
				</ButtonPrimary>
			)}
		</Upload>
	)
}
