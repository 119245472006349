import { useState } from "react"
import { Segmented } from "components/ui/segmented/Segmented"
import { FileInfoKeywords } from "../FileInfoKeywords/FileInfoKeywords"
import { FoundItemsObjects } from "./layout/FoundItemsObjects/FoundItemsObjects"
import { useSearchParams } from "react-router-dom"
import { useDrawingKeywords, useSpecificationKeywords } from "hooks/drawings.hook"
import type { TDrawingStatusProps } from "types/drawer.type"
import style from "./FoundItems.module.scss"

const { foundItems, segmented } = style

export const FoundItems = ({ drawingStatus }: TDrawingStatusProps) => {
	const [segmentedOption, setSegmentedOption] = useState<string | number>("Specification keywords")

	const [searchParams] = useSearchParams()

	const drawerId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: drawingKeywords,
		isPending: isKeywordsPending,
		isError: errorFetchingKewords,
	} = useDrawingKeywords(drawerId, processingId, drawingStatus)
	const {
		data: specificationKeywords,
		isPending: isSpecificationKeywordsPending,
		isError: errorFetchingSpecificationKeywords,
	} = useSpecificationKeywords(drawerId, processingId, drawingStatus)

	const segmentedOptionChangeHandle = (value: string | number) => {
		setSegmentedOption(value)
	}

	return (
		<div className={foundItems}>
			<div className={segmented}>
				<Segmented
					options={["Specification keywords", "Drawing keywords", "Objects"]}
					onChange={segmentedOptionChangeHandle}
				/>
			</div>
			<div>
				<div data-testid="specificationKeywords">
					{segmentedOption === "Specification keywords" ? (
						<FileInfoKeywords
							drawingKeywords={specificationKeywords}
							isKeywordsPending={isSpecificationKeywordsPending}
							errorFetchingKewords={errorFetchingSpecificationKeywords}
						/>
					) : null}
				</div>
			</div>
			<div data-testid="drawingKeywords">
				{segmentedOption === "Drawing keywords" ? (
					<FileInfoKeywords
						drawingKeywords={drawingKeywords}
						isKeywordsPending={isKeywordsPending}
						errorFetchingKewords={errorFetchingKewords}
					/>
				) : null}
			</div>
			<div data-testid="objectsFound">
				{segmentedOption === "Objects" ? <FoundItemsObjects drawingStatus={drawingStatus} /> : null}
			</div>
		</div>
	)
}
