import axios from "axios"
import { useUploadPresignedUrlStore } from "store/uploadFiles.store"
import Cookies from "js-cookie"

const apiAndVersion = "/api/v1/"
// Prod API
const prodApi = import.meta.env.VITE_API_URL + apiAndVersion
// Dev mode
const devMode = import.meta.env.MODE === "development"
// Set API based on mode
const API = devMode ? apiAndVersion : prodApi
// Set timeout for axios instance
const timeout = import.meta.env.VITE_AXIOS_INSTANCE_TIMEOUT

// Main axios instance
export const axiosInstance = axios.create({
	baseURL: API,
	timeout: timeout,
	headers: { "Content-Type": "application/json" },
})

axiosInstance.interceptors.request.use((config) => {
	const token = Cookies.get("token")
	if (token) {
		config.headers.Authorization = `Token ${token}`
	}
	return config
})

// Non authenticated axios instance
export const axiosNonAuthInstance = axios.create({
	baseURL: API,
	timeout: timeout,
	headers: { "Content-Type": "application/json" },
})

// Axios instance for uploading files
export const axiosUploadFileInstance = () => {
	const { presignedUrl } = useUploadPresignedUrlStore.getState()

	return axios.create({
		baseURL: presignedUrl,
		timeout: 0,
	})
}
