import { useEffect, useRef } from "react"
import MonacoEditor, { OnChange, useMonaco } from "@monaco-editor/react"
import * as monaco from "monaco-editor"
import { CopyIcon } from "../icons/iaAgent/CopyIcon"
import { copyToClipboard } from "helpers/utils.helper"
import { useMessage } from "hooks/message.hook"
import styles from "./Editor.module.scss"

const { editorWrapper, editorHeader } = styles

type Props = {
	height?: string | number
	value: string | undefined
	isReadOnly: boolean
	backgroundColor?: string
	textColor?: string
	fontSize?: number
	defaultLanguage?: string
	onEditorChange?: OnChange | undefined
}

export const Editor = ({
	value,
	isReadOnly,
	height = 350,
	backgroundColor = "#F2F3F7",
	textColor = "#373531",
	fontSize = 13,
	defaultLanguage = "text",
	onEditorChange,
}: Props) => {
	const monaco = useMonaco()
	const { success: successMessage } = useMessage()
	const isThemeRegistered = useRef(false)
	const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)

	// Define Monaco theme
	useEffect(() => {
		if (monaco && !isThemeRegistered.current) {
			try {
				monaco.editor.defineTheme("customTheme", {
					base: "vs",
					inherit: true,
					rules: [
						{
							token: "",
							foreground: textColor, // Text color
						},
					],
					colors: {
						"editor.background": backgroundColor, // Background color
						"editor.lineHighlightBorder": "#00000000", // Remove line highlight
					},
				})

				monaco.editor.setTheme("customTheme")
				isThemeRegistered.current = true
			} catch (error) {
				console.error("Error defining Monaco theme:", error)
			}
		}
	}, [monaco, backgroundColor, textColor])

	// Handle editor did mount
	const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor) => {
		editorRef.current = editor
	}

	// Copy to clipboard
	const copyToClipboardHandle = () => {
		if (editorRef.current) {
			const instruction = editorRef.current.getValue()
			copyToClipboard(instruction)
			successMessage("Instruction copied to clipboard")
		}
	}

	return (
		<div className={editorWrapper}>
			<div className={editorHeader}>
				<span>{defaultLanguage}</span>
				<button type="button" onClick={copyToClipboardHandle}>
					<CopyIcon /> Copy code
				</button>
			</div>
			<MonacoEditor
				height={height}
				width="100%"
				onMount={handleEditorDidMount}
				value={value}
				defaultLanguage={defaultLanguage}
				theme="customTheme"
				onChange={onEditorChange}
				options={{
					minimap: { enabled: false },
					lineNumbers: "off",
					fontSize: fontSize,
					readOnly: isReadOnly,
					scrollbar: {
						vertical: "hidden",
						horizontal: "hidden",
					},
					overviewRulerLanes: 0,
				}}
			/>
		</div>
	)
}
