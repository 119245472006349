import { ChangeEvent, useState } from "react"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { ConfigurationModal } from "components/ui/modals/ConfigurationModal/ConfigurationModal"
import { H2 } from "components/ui/typography/titles/Titles"
import { InstructionsForm } from "../InstructionsForm/InstructionsForm"
import { useInstructionsStore } from "store/aiAgent.store"
import { useAddInstruction } from "hooks/AIAgent.hook"
import { useMessage } from "hooks/message.hook"
import { AxiosError } from "axios"
import { useApiErrorFormatter } from "hooks/apiErrorFormatter.hook"
import style from "./AddInstruction.module.scss"

const { addInstructionsButton, addInstruction, container, title, form } = style

type Props = { refetchInstructions: () => void }

export const AddInstruction = ({ refetchInstructions }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const { instruction, setInstruction, clearInstructions } = useInstructionsStore()

	const { mutate: addInstructionMutation } = useAddInstruction()
	const { success: successMessage } = useMessage()
	const { formatError } = useApiErrorFormatter()

	const setSectionNumbers = (newNumber: string[]) => {
		setInstruction({ sections: newNumber })
	}

	const onEditorChange = (value: string | undefined) => {
		setInstruction({ prompt: value })
	}

	const onQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
		setInstruction({ question: e.target.value })
	}

	const saveHandle = () => {
		addInstructionMutation(instruction, {
			onSuccess: () => {
				closeModalHandle()
				successMessage("Instruction added successfully")
				refetchInstructions()
			},
			onError: (e) => {
				if (e instanceof AxiosError) {
					formatError(e)
				}
			},
		})
	}

	const closeModalHandle = () => {
		clearInstructions()
		setIsModalOpen(false)
	}

	return (
		<>
			<ButtonPrimary onClick={() => setIsModalOpen(true)} className={addInstructionsButton}>
				<span data-testid="addInstructionButton">Add instruction</span>
			</ButtonPrimary>
			<ConfigurationModal
				isModalOpen={isModalOpen}
				setModalClose={closeModalHandle}
				saveHandle={saveHandle}
				submitButtonText="Add instruction"
			>
				<div className={addInstruction}>
					<div className={container}>
						<H2 className={title}>Add new instruction</H2>
						<div className={form}>
							<InstructionsForm
								setNumbers={setSectionNumbers}
								numbers={instruction.sections}
								onEditorChange={onEditorChange}
								onQuestionChange={onQuestionChange}
								editorValue={instruction.prompt}
								question={instruction.question}
							/>
						</div>
					</div>
				</div>
			</ConfigurationModal>
		</>
	)
}
