export const QuestionArrowIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.00977 6V7C3.00977 8.6569 4.35291 10 6.00977 10H16.9901M16.9901 10C15.6865 8.6443 14.3396 7.32983 13.0098 6M16.9901 10C15.6865 11.3557 14.3396 12.6702 13.0098 14"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
