import { ChangeEvent } from "react"
import { H2 } from "components/ui/typography/titles/Titles"
import { InstructionsForm } from "../InstructionsForm/InstructionsForm"
import { ConfigurationModal } from "components/ui/modals/ConfigurationModal/ConfigurationModal"
import { useInstructionsStore } from "store/aiAgent.store"
import { useUpdateInstruction } from "hooks/AIAgent.hook"
import { useApiErrorFormatter } from "hooks/apiErrorFormatter.hook"
import { AxiosError } from "axios"
import style from "./EditInstruction.module.scss"

const { editInstruction, container, form, title } = style

type Props = { isModalOpen: boolean; setModalClose: () => void; refetchInstructions: () => void }

export const EditInstruction = ({ isModalOpen, setModalClose, refetchInstructions }: Props) => {
	const { instruction, clearInstructions, setInstruction } = useInstructionsStore()
	const { mutate: updateInstruction } = useUpdateInstruction()
	const { formatError } = useApiErrorFormatter()

	const saveHandle = () => {
		updateInstruction(
			{
				question: instruction.question,
				prompt: instruction.prompt,
				sections: instruction.sections,
				questionId: instruction.questionId,
			},
			{
				onSuccess: () => {
					refetchInstructions()
					setModalClose()
					clearInstructions()
				},
				onError: (e) => {
					if (e instanceof AxiosError) {
						formatError(e)
					}
				},
			},
		)
	}

	const setSectionNumbers = (newNumber: string[]) => {
		setInstruction({ sections: newNumber })
	}

	const onEditorChange = (value: string | undefined) => {
		setInstruction({ prompt: value })
	}

	const onQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
		setInstruction({ question: e.target.value })
	}

	return (
		<ConfigurationModal
			isModalOpen={isModalOpen}
			setModalClose={setModalClose}
			saveHandle={saveHandle}
			submitButtonText="Save"
		>
			<div className={editInstruction}>
				<div className={container}>
					<H2 className={title}>Edit instruction</H2>
					<div className={form}>
						<InstructionsForm
							question={instruction.question}
							onQuestionChange={onQuestionChange}
							editorValue={instruction.prompt}
							onEditorChange={onEditorChange}
							numbers={instruction.sections}
							setNumbers={setSectionNumbers}
						/>
					</div>
				</div>
			</div>
		</ConfigurationModal>
	)
}
