import { ObjectsCategories } from "../ObjectsCategories/ObjectsCategories"
import { Keywords } from "../Keywords/Keywords"
import { AIInstructions } from "../AIInsructions/AIInstructions"
import { useConfigurationTabsStore } from "store/configuration.store"

export const ConfigurationContent = () => {
	const { configurationTabsIndex } = useConfigurationTabsStore()

	switch (configurationTabsIndex) {
		case 0:
			return <Keywords />
		case 1:
			return <ObjectsCategories />
		case 2:
			return <AIInstructions />
	}
}
