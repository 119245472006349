import { useCallback, useMemo, useState } from "react"
import { Space } from "antd"
import { ButtonOptions } from "components/ui/buttons/ButtonOptions/ButtonOptions"
import { Dropdown } from "components/ui/dropdown/Dropdown"
import { useChangeUserRole, useUsers } from "hooks/user.hook"
import { useMessage } from "hooks/message.hook"
import { AxiosError } from "axios"
import { Modal } from "components/ui/modals/Modal/Modal"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { ButtonWarning } from "components/ui/buttons/ButtonWarning/ButtonWarning"
import style from "./TeamTable.module.scss"

const { modalButtos, question, warning } = style

type Props = {
	availableRoles: string[]
	id: number
	name: string
}

export const TeamTableDropdown = ({ availableRoles, id, name }: Props) => {
	const { mutate: changeUserRoleMutation } = useChangeUserRole()
	const { refetch: refetchUsers } = useUsers()
	const { success: successMessage, error: errorMessage } = useMessage()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [user, setUser] = useState({ id: 0, newRole: "" })

	// Making request and change user role
	const setUserRole = useCallback(() => {
		changeUserRoleMutation(user, {
			onSuccess: () => {
				refetchUsers()
				successMessage(`${name} is now an ${user.newRole}!`)
				setIsModalOpen(false)
			},
			onError: (e) => {
				if (e instanceof AxiosError) {
					const status = e.response?.status
					if (status === 401) {
						errorMessage("User already has the provided role")
					} else {
						errorMessage("Something went wrong, please try again later")
					}
				}
			},
		})
	}, [changeUserRoleMutation, user, refetchUsers, successMessage, name, errorMessage])

	// Dynamic dropdown items based on available roles
	const manageItems = useMemo(() => {
		const items = availableRoles.map((role, index) => ({
			key: `${index + 1}`,
			label: <span>{`Make an ${role}`}</span>,
			// Open the modal and set new role
			onClick: () => {
				setUser({
					newRole: role,
					id: id,
				})
				setIsModalOpen(true)
			},
		}))

		return items
	}, [availableRoles, id])

	return (
		<>
			<Dropdown items={manageItems}>
				<a onClick={(e) => e.preventDefault()}>
					<Space>
						<ButtonOptions />
					</Space>
				</a>
			</Dropdown>
			<Modal
				title="Confirm role change"
				width={600}
				isModalOpen={isModalOpen}
				setModalClose={() => setIsModalOpen(false)}
			>
				<p className={question}>
					Are you sure you want to change the role of <strong>{name}</strong> to <strong>{user.newRole}</strong>?
				</p>
				<p className={warning}>
					This action will modify the user's permissions and may affect their access to certain features. Please confirm
					to proceed with this change.
				</p>
				<div className={modalButtos}>
					<ButtonPrimary onClick={() => setIsModalOpen(false)}>Cancel</ButtonPrimary>
					<ButtonWarning onClick={setUserRole}>Confirm</ButtonWarning>
				</div>
			</Modal>
		</>
	)
}
