import { useState } from "react"
import { Segmented } from "components/ui/segmented/Segmented"
import { useDrawingKeywordsConfig, useDrawingSpecificationKeywordsConfig } from "hooks/drawings.hook"
import { FileInfoKeywords } from "../FileInfoKeywords/FileInfoKeywords"
import { useSearchParams } from "react-router-dom"
import { ConfigurationsObjects } from "./layout/ConfigurationsObjects/ConfigurationsObjects"
import type { TDrawingStatusProps } from "types/drawer.type"
import style from "./Configurations.module.scss"

const { configurations, segmented } = style

export const Configurations = ({ drawingStatus }: TDrawingStatusProps) => {
	const [segmentedOption, setSegmentedOption] = useState<string | number>("Specification keywords")
	const [searchParams] = useSearchParams()

	const drawerId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: drawingKeywords,
		isPending: isKeywordsPending,
		isError: errorFetchingKewords,
	} = useDrawingKeywordsConfig(drawerId, processingId, drawingStatus)
	const {
		data: specificationKeywords,
		isPending: isSpecificationKeywordsPending,
		isError: errorFetchingSpecificationKewords,
	} = useDrawingSpecificationKeywordsConfig(drawerId, processingId, drawingStatus)

	const segmentedOptionChangeHandle = (value: string | number) => {
		setSegmentedOption(value)
	}

	return (
		<div className={configurations}>
			<div className={segmented}>
				<Segmented
					options={["Specification keywords", "Drawing keywords", "Objects"]}
					onChange={segmentedOptionChangeHandle}
				/>
			</div>
			{segmentedOption === "Specification keywords" ? (
				<FileInfoKeywords
					drawingKeywords={specificationKeywords}
					isKeywordsPending={isSpecificationKeywordsPending}
					errorFetchingKewords={errorFetchingSpecificationKewords}
				/>
			) : null}
			{segmentedOption === "Drawing keywords" ? (
				<FileInfoKeywords
					drawingKeywords={drawingKeywords}
					isKeywordsPending={isKeywordsPending}
					errorFetchingKewords={errorFetchingKewords}
				/>
			) : null}
			<div data-testid="configurationsObjects">
				{segmentedOption === "Objects" ? <ConfigurationsObjects drawingStatus={drawingStatus} /> : null}
			</div>
		</div>
	)
}
