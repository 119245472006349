import { Skeleton } from "components/ui/skeleton/Skeleton"
import { useDrawingObjectsConfig } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import { Collapse } from "components/ui/collapse/Collapse"
import { useCollapseObjects } from "hooks/collapseObjects.hook"
import { ConfigurationsObjectsColumns } from "./ConfigurationsObjectsColumns"
import { isAxiosError } from "axios"
import type { TDrawingStatusProps } from "types/drawer.type"
import style from "./ConfigurationsObjects.module.scss"

const { skeleton, configurationsObjects, errorMessage } = style

export const ConfigurationsObjects = ({ drawingStatus }: TDrawingStatusProps) => {
	const [searchParams] = useSearchParams()
	const drawerId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: objectsConfigData,
		isPending: isObjectsConfigPending,
		error: drawingObjectConfigError,
	} = useDrawingObjectsConfig(drawerId, processingId, drawingStatus)

	const { tableColumns } = ConfigurationsObjectsColumns()

	const { generateCollapseItems } = useCollapseObjects(tableColumns)
	const collapseItems = generateCollapseItems(objectsConfigData)

	if (isObjectsConfigPending) {
		return (
			<div className={skeleton} data-testid="skeleton">
				<Skeleton rows={12} />
			</div>
		)
	}

	if (drawingObjectConfigError) {
		if (isAxiosError(drawingObjectConfigError)) {
			const status = drawingObjectConfigError.response?.status
			if (status === 404) {
				return <div className={errorMessage}>Objects categories config not found</div>
			} else {
				return <div className={errorMessage}>Something went wrong, please try again later</div>
			}
		} else {
			return <div className={errorMessage}>An unexpected error occurred</div>
		}
	}

	return (
		<div className={configurationsObjects} data-testid="configurationsObjects">
			{collapseItems ? (
				<Collapse items={collapseItems} defaultActiveKey={collapseItems.map((item) => item.key)} />
			) : null}
		</div>
	)
}
