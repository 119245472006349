import { H2 } from "components/ui/typography/titles/Titles"
import { AddInstruction } from "./layout/AddInstruction/AddInstruction"
import { InstructionsTable } from "./layout/InstructionsTable/InstructionsTable"
import { useGetInstructions } from "hooks/AIAgent.hook"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import style from "./AIInstructions.module.scss"

const { aiInstructions, headerBlock, title, skeletonContainer } = style

export const AIInstructions = () => {
	const { data: instructionsData, isPending: instructionsPending, refetch: refetchInstructions } = useGetInstructions()

	if (instructionsPending) {
		return (
			<div className={skeletonContainer}>
				<Skeleton rows={12} />
			</div>
		)
	}

	return (
		<div className={aiInstructions}>
			<div className={headerBlock}>
				<H2 className={title}>AI Instructions</H2>
				<AddInstruction refetchInstructions={refetchInstructions} />
			</div>
			<InstructionsTable instructionsData={instructionsData} refetchInstructions={refetchInstructions} />
		</div>
	)
}
