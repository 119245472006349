import { useState } from "react"
import { ThumbsUpIcon } from "components/ui/icons/generalFeedback/ThumbsUpIcon"
import { ThumbsDownIcon } from "components/ui/icons/generalFeedback/ThumbsDownIcon"
import { useGeneralFeedbackStore, useThankYouMessageStore } from "store/drawer.store"
import { NegativeFeedbackModal } from "components/ui/NegativeFeedbackModal/NegativeFeedbackModal"
import { useSearchParams } from "react-router-dom"
import { useFeedbackMutation } from "hooks/feedback.hook"
import type { TDrawingStatus } from "types/drawer.type"
import styles from "./GeneralFeedback.module.scss"

const { generalFeedbackStyle, text, icons, icon, active } = styles

// Get processingUrl from current window location
const processingUrl = window.location.href

export const GeneralFeedback = ({ drawingStatus }: { drawingStatus: TDrawingStatus }) => {
	const { generalFeedback, setGeneralFeedback } = useGeneralFeedbackStore()
	const { setThankYouMessage } = useThankYouMessageStore()

	const [isModalOpen, setIsModalOpen] = useState(false)

	const [searchParams] = useSearchParams()

	const { submitFeedback } = useFeedbackMutation()

	// Get drawingId and processingId from URL or store
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const onClickHandle = (type: string) => {
		const formData = new FormData()
		formData.append("type", "POSITIVE")
		formData.append("processing_url", processingUrl)

		switch (type) {
			case "thumbsUp":
				setGeneralFeedback(type) // Set feedback type
				submitFeedback({ formData, processingId, drawingId }, drawingStatus)
				break

			// Open negative feedback modal
			case "thumbsDown":
				setIsModalOpen(true)
				break
		}
	}

	const modalCloseHandle = () => {
		setIsModalOpen(false)
		setThankYouMessage(false)
	}

	return (
		<>
			<div className={generalFeedbackStyle}>
				<span className={text}>Give us feedback</span>
				<div className={icons}>
					<div
						className={`${icon} ${generalFeedback === "thumbsUp" ? active : ""}`}
						onClick={() => onClickHandle("thumbsUp")}
						data-testid="thumbsUp"
					>
						<ThumbsUpIcon active={generalFeedback === "thumbsUp"} />
					</div>
					<div
						className={`${icon} ${generalFeedback === "thumbsDown" ? active : ""}`}
						onClick={() => onClickHandle("thumbsDown")}
						data-testid="thumbsDown"
					>
						<ThumbsDownIcon active={generalFeedback === "thumbsDown"} />
					</div>
				</div>
			</div>
			<NegativeFeedbackModal
				drawingId={drawingId}
				processingId={processingId}
				isModalOpen={isModalOpen}
				processingUrl={processingUrl}
				setModalClose={modalCloseHandle}
				drawingStatus={drawingStatus}
				titleText="We're sorry the processing didn't go as expected!"
			/>
		</>
	)
}
