import { MouseEvent, useState } from "react"
import { InstructionsTableColumns } from "./InstructionsTableColumns"
import { TableConfiguration } from "components/ui/tables/TableConfiguration/TableConfiguration"
import { Editor } from "components/ui/editor/Editor"
import { CollapseBlueIcon } from "components/ui/icons/collapseIcon/CollapseBlueIcon"
import { CollapseGrayIcon } from "components/ui/icons/collapseIcon/CollapseGrayIcon"
import { useInstructionsStore } from "store/aiAgent.store"
import { EditInstruction } from "../EditInstruction/EditInstruction"
import { InstructionsTableUtils } from "./InstructionsTableUtils"
import type { ExpandableConfig } from "antd/es/table/interface"
import type { IInstructionsTable } from "types/table.type"
import style from "./InstructionsTable.module.scss"

const { expandedRow } = style

type Props = { instructionsData: IInstructionsTable[]; refetchInstructions: () => void }

export const InstructionsTable = ({ instructionsData, refetchInstructions }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { clearInstructions } = useInstructionsStore()
	InstructionsTableUtils({ refetchInstructions })

	// Logic for modal opening
	const setEditInstructionModalOpen = () => setIsModalOpen(true)

	// Pass columns to table
	const tableColumns = InstructionsTableColumns({ refetchInstructions, setEditInstructionModalOpen })

	// Pass data for table
	const tableData = instructionsData?.map((item) => ({
		id: item.id,
		key: item.id,
		question: item.question,
		sections: item.sections,
		status: item.status,
		active: {
			status: item.status,
			questionId: item.id,
		},
		prompt: item.prompt,
		action: item,
	}))

	// Logic for modal closing
	const setEditInstructionModalClose = () => {
		setIsModalOpen(false)
		clearInstructions()
	}

	// Expandable items for table
	const expandalble: ExpandableConfig<IInstructionsTable> = {
		expandedRowRender: (record) => <Editor value={record.prompt} isReadOnly={true} />,
		expandRowByClick: true,
		expandedRowClassName: () => expandedRow,
		expandIcon: ({ expanded, onExpand, record }) =>
			expanded ? (
				<div data-testid="collapseIconOpened">
					<CollapseBlueIcon rotate={90} onClick={(e) => onExpand(record, e as MouseEvent<HTMLElement>)} />
				</div>
			) : (
				<CollapseGrayIcon onClick={(e) => onExpand(record, e as MouseEvent<HTMLElement>)} />
			),
	}

	// Handle row hover
	const onRowHandle = () => ({
		onMouseEnter: () => {
			document.body.style.cursor = "pointer"
		},
		onMouseLeave: () => {
			document.body.style.cursor = "default"
		},
	})

	return (
		<>
			<TableConfiguration columns={tableColumns} data={tableData} expandable={expandalble} onRow={onRowHandle} />
			<EditInstruction
				isModalOpen={isModalOpen}
				setModalClose={setEditInstructionModalClose}
				refetchInstructions={refetchInstructions}
			/>
		</>
	)
}
