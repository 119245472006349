import { useEffect, useState } from "react"
import { TextArea } from "components/ui/inputs/TextArea"
import { Modal } from "components/ui/modals/Modal/Modal"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useGeneralFeedbackStore, useThankYouMessageStore } from "store/drawer.store"
import { Screenshots } from "./layout/Screenshots/Screenshots"
import { Form } from "antd"
import { FeedbackThankYou } from "components/ui/FeedbackThankYou/FeedbackThankYou"
import { Spin } from "components/ui/spin/Spin"
import type { TDrawingStatus } from "types/drawer.type"
import styles from "./NegativeFeedbackModal.module.scss"
import { useFeedbackMutation } from "hooks/feedback.hook"

const { negativeFeedbackModal, text, textAreaWrapper, screenshotWrapper, submitButton, textSmall, title } = styles

type Props = {
	isModalOpen: boolean
	setModalClose: () => void
	drawingId: number
	processingId: number | string | null
	processingUrl: string
	drawingStatus: TDrawingStatus
	titleText: string
}

export interface IExtendedFeedbackFile extends File {
	preview: string
	id: number
}

// Max size for image
const imageSize = import.meta.env.VITE_UPLOADING_FEEDBACK_IMAGE_MAX_SIZE

export const NegativeFeedbackModal = ({
	isModalOpen,
	setModalClose,
	drawingId,
	processingId,
	processingUrl,
	drawingStatus,
	titleText,
}: Props) => {
	const [submittable, setSubmittable] = useState(false)
	const [files, setFiles] = useState<IExtendedFeedbackFile[]>([])

	const { setGeneralFeedback } = useGeneralFeedbackStore()
	const { setThankYouMessage, thankYouMessage } = useThankYouMessageStore()

	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	// Custom hook to submit feedback
	const { submitFeedback, isFeedbackPending } = useFeedbackMutation({
		onSuccess: () => {
			form.resetFields() // Clear inputs
			setFiles([])
			setGeneralFeedback("thumbsDown") // Set feedback type
			setThankYouMessage(true) // Show thank you message
		},
		onError: () => {
			setSubmittable(true)
		},
	})

	const onFilesAdded = (newFiles: IExtendedFeedbackFile[]) => {
		setFiles((prevFiles) =>
			prevFiles.concat(
				newFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
						id: Date.now(),
					}),
				),
			),
		)
	}

	// Submit feedback to server
	const onSubmitHandle = () => {
		const formData = new FormData()
		formData.append("drawingId", String(drawingId))
		formData.append("processingId", String(processingId))
		formData.append("feedback_text", values.feedback)
		formData.append("type", "NEGATIVE")
		formData.append("processing_url", processingUrl)

		files.forEach((file: IExtendedFeedbackFile) => {
			formData.append("screenshots", file)
		})

		submitFeedback({ formData, processingId, drawingId }, drawingStatus)
	}

	// Removing images
	const removeImageHandle = (id: number) => {
		setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id))
	}

	useEffect(() => {
		const hasFeedback = values?.feedback?.length > 0
		const maxCount = 10
		const hasOversizedFile = files.some((file) => file.size && file.size > imageSize)
		const exceedsMaxCount = files.length > maxCount

		if (hasOversizedFile) {
			form.setFields([
				{
					name: "screenshots",
					errors: ["File size must be less than 5MB"],
				},
			])
		} else if (exceedsMaxCount) {
			form.setFields([
				{
					name: "screenshots",
					errors: [`You can only add up to ${maxCount} images`],
				},
			])
		} else {
			form.setFields([
				{
					name: "screenshots",
					errors: [],
				},
			])
		}

		setSubmittable(hasFeedback && !hasOversizedFile && !exceedsMaxCount)
	}, [values, files, form])

	// Disable submit button if request is pending
	useEffect(() => {
		if (isFeedbackPending) {
			setSubmittable(false)
		}
	}, [isFeedbackPending])

	useEffect(() => {
		return () =>
			files.forEach((file) => {
				if (file.preview) {
					URL.revokeObjectURL(file.preview)
				}
			})
	}, [files])

	return (
		<Modal isModalOpen={isModalOpen} setModalClose={setModalClose} width={533}>
			{thankYouMessage ? (
				<FeedbackThankYou
					title="Thank you for your feedback!"
					text="Our team will keep you updated via email with the outcome. Feel free to give feedback as many times as
				necessary."
				/>
			) : (
				<Form form={form} onFinish={onSubmitHandle}>
					<div className={negativeFeedbackModal}>
						<span className={title}>{titleText}</span>
						<span className={text}>
							Describe the problem, and our team will work on resolving it as quickly as possible.
						</span>
						<Form.Item className={textAreaWrapper} name="feedback" rules={[{ required: true }]}>
							<TextArea placeholder="Share your feedback" minRows={5} />
						</Form.Item>
						<Form.Item className={screenshotWrapper} name="screenshots">
							<Screenshots files={files} onFilesAdded={onFilesAdded} onRemoveFile={removeImageHandle} />
						</Form.Item>
						<span className={`${text} ${textSmall}`}>
							Upload up to 10 files, maximum file size 5 MB, acceptable file types .png, .jpeg, .jpg
						</span>
						<ButtonPrimary submittable={submittable} className={submitButton}>
							{isFeedbackPending ? <Spin size="default" /> : "Send"}
						</ButtonPrimary>
					</div>
				</Form>
			)}
		</Modal>
	)
}
