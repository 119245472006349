import { axiosInstance } from "api/axios.instance"
import { TInstructionsData } from "types/aiAget.type"

class AIAgentService {
	static BASE_INSTRUCTIONS_URL = "questions"
	static BASE_DRAWINGS_URL = "drawings"

	async getInstructions() {
		return axiosInstance.get(`${AIAgentService.BASE_INSTRUCTIONS_URL}/`)
	}

	async getQuestions(drawingId: number) {
		return axiosInstance.get(`${AIAgentService.BASE_DRAWINGS_URL}/${drawingId}/questions`)
	}

	async askQuestion(drawingId: number, questionId: number, subject: string) {
		return axiosInstance.post(`${AIAgentService.BASE_DRAWINGS_URL}/${drawingId}/questions/${questionId}/`, {
			nats_subject: subject,
		})
	}

	async sendFeedback(
		drawingId: number,
		questionId: number | undefined,
		langsmithTraceId: string | undefined,
		type: string,
		feedback?: string,
	) {
		return axiosInstance.post(`${AIAgentService.BASE_DRAWINGS_URL}/${drawingId}/questions/${questionId}/feedback`, {
			langsmith_trace_id: langsmithTraceId,
			type,
			feedback_text: feedback,
		})
	}

	async addInstruction(intruction: TInstructionsData) {
		return axiosInstance.post(`${AIAgentService.BASE_INSTRUCTIONS_URL}/`, intruction)
	}

	async updateInstruction({ question, prompt, sections, questionId }: TInstructionsData) {
		return axiosInstance.patch(`${AIAgentService.BASE_INSTRUCTIONS_URL}/${questionId}/`, {
			question,
			prompt,
			sections,
		})
	}

	async deleteInstruction(questionId: number) {
		return axiosInstance.delete(`${AIAgentService.BASE_INSTRUCTIONS_URL}/${questionId}/`)
	}

	async updateInstructionStatus(questionId: number, state: string) {
		return axiosInstance.patch(`${AIAgentService.BASE_INSTRUCTIONS_URL}/${questionId}/state/`, { state })
	}
}
export default new AIAgentService()
