import { useState } from "react"
import { Form } from "antd"
import { Modal } from "components/ui/modals/Modal/Modal"
import { H3 } from "components/ui/typography/titles/Titles"
import { TextArea } from "components/ui/inputs/TextArea"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useSendFeedback } from "hooks/AIAgent.hook"
import { useApiErrorFormatter } from "hooks/apiErrorFormatter.hook"
import { FeedbackThankYou } from "components/ui/FeedbackThankYou/FeedbackThankYou"
import { Spin } from "components/ui/spin/Spin"
import { AxiosError } from "axios"
import style from "./NegativeFeedbackModal.module.scss"

const { title, text, button } = style

type Props = {
	isModalOpen: boolean
	setModalClose: () => void
	drawingId: number
	questionId: number | undefined
	langsmithTraceId: string | undefined
	setButtonActive: (feedbackType: string) => void
}

export const NegativeFeedbackModal = ({
	isModalOpen,
	setModalClose,
	drawingId,
	questionId,
	langsmithTraceId,
	setButtonActive,
}: Props) => {
	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	const { mutate: sendFeedbackMutation, isPending: sendFeedbackPending } = useSendFeedback()
	const { formatError } = useApiErrorFormatter()

	const [showThankYouMessage, setShowThankYouMessage] = useState(false)

	const sendFeedbackHandle = () => {
		sendFeedbackMutation(
			{
				drawingId,
				questionId,
				langsmithTraceId: langsmithTraceId,
				type: "NEGATIVE",
				feedback: values.feedback,
			},
			{
				onSuccess: () => {
					form.resetFields()
					setButtonActive("NEGATIVE")
					setShowThankYouMessage(true)
				},
				onError: (e) => {
					if (e instanceof AxiosError) {
						formatError(e)
						setButtonActive("")
					}
				},
			},
		)
	}

	const modalCloseHandle = () => {
		setModalClose()
		setShowThankYouMessage(false)
	}

	return (
		<Modal isModalOpen={isModalOpen} setModalClose={modalCloseHandle} width={500}>
			{showThankYouMessage ? (
				<FeedbackThankYou
					title="Thank you for your feedback!"
					text="Feel free to give feedback as many times as necessary."
				/>
			) : (
				<>
					<H3 className={title}>We’re sorry the response didn’t meet your expectations!</H3>
					<p className={text}>Describe the problem, and our team will work on resolving it as quickly as possible.</p>
					<Form form={form}>
						<Form.Item name="feedback" rules={[{ required: true, message: "Please type your feedback" }]}>
							<TextArea placeholder="Share your feedback" minRows={6} />
						</Form.Item>
						<ButtonPrimary
							onClick={sendFeedbackHandle}
							submittable={sendFeedbackPending ? false : true}
							className={button}
						>
							{sendFeedbackPending ? <Spin /> : "Send"}
						</ButtonPrimary>
					</Form>
				</>
			)}
		</Modal>
	)
}
