import { create } from "zustand"
import type { TChat } from "types/aiAget.type"
import { TInstructions } from "types/aiAget.type"

export const useChatStore = create<TChat>((set) => ({
	chatHistory: {
		questions: [],
		answers: [],
	},

	isChatOpen: false,

	addQuestion: (question) =>
		set((state) => ({
			chatHistory: {
				...state.chatHistory,
				questions: [...state.chatHistory.questions, question],
				answers: [...state.chatHistory.answers, ""],
			},
		})),
	// This code appends a new message to the last answer in the chat history. It does this by mapping over the answers array and concatenating the new message to the last answer, leaving the other answers unchanged.
	addAnswer: (message) =>
		set((state) => {
			const lastIndex = state.chatHistory.answers.length - 1
			return {
				chatHistory: {
					...state.chatHistory,
					answers: state.chatHistory.answers.map((answer, index) => (index === lastIndex ? answer + message : answer)),
				},
			}
		}),
	clearChatHistory: () => set({ chatHistory: { questions: [], answers: [] } }),

	toggleChat: () => set((state) => ({ isChatOpen: !state.isChatOpen })),
	openChat: () => set({ isChatOpen: true }),
	closeChat: () => set({ isChatOpen: false }),
}))

// Add new instruction
const initialInstruction = {
	questionId: null,
	question: "",
	prompt: "",
	sections: [],
}

export const useInstructionsStore = create<TInstructions>((set) => ({
	instruction: initialInstruction,

	setInstruction: (updates) =>
		set((state) => ({
			instruction: { ...state.instruction, ...updates },
		})),

	clearInstructions: () =>
		set({
			instruction: initialInstruction,
		}),
}))
