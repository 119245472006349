import { useState } from "react"
import { Spin } from "antd"
import { ThumbsDownIcon } from "components/ui/icons/iaAgent/ThumbsDownIcon"
import { ThumbsUpIcon } from "components/ui/icons/iaAgent/ThumbsUpIcon"
import { useFeedbackMutation } from "hooks/feedback.hook"
import { useSearchParams } from "react-router-dom"
import { NegativeFeedbackModal } from "components/ui/NegativeFeedbackModal/NegativeFeedbackModal"
import { useThankYouMessageStore } from "store/drawer.store"
import type { TDrawingStatus } from "types/drawer.type"
import style from "./GeneralFeedbackForResponse.module.scss"

const { chatBottomBar, feedbackBlock, feedBackIcons, buttons, active } = style

// Get processingUrl from current window location
const processingUrl = window.location.href

export const GeneralFeedbackForResponse = () => {
	const [isModalOpen, setModalOpen] = useState(false)
	const [buttonActive, setButtonActive] = useState("")

	const { setThankYouMessage } = useThankYouMessageStore()

	const [searchParams] = useSearchParams()

	const drawingId = searchParams.get("drawingID")
	const processingId = searchParams.get("processingID")
	const drawingStatus = searchParams.get("status") as TDrawingStatus

	const { submitFeedback, isFeedbackPending } = useFeedbackMutation({
		onSuccess: () => {
			setButtonActive("POSITIVE")
		},
		onError: () => {
			setButtonActive("")
		},
	})

	const sendFeedbackHandle = (feedbackType: string) => {
		const formData = new FormData()
		formData.append("type", "POSITIVE")
		formData.append("processing_url", processingUrl)

		// Open modal if feedback is negative and return
		if (feedbackType === "NEGATIVE") {
			setModalOpen(true)
			return
		}

		submitFeedback({ formData, drawingId, processingId }, drawingStatus)
	}

	const modalCloseHandle = () => {
		setThankYouMessage(false)
		setModalOpen(false)
	}

	return (
		<>
			<div className={chatBottomBar}>
				<div className={feedbackBlock}>
					<span>Good answer?</span>
					<div className={feedBackIcons}>
						{/* Show spinner when positive feedback posting */}
						{isFeedbackPending ? (
							<Spin />
						) : (
							<button
								onClick={() => sendFeedbackHandle("POSITIVE")}
								className={`${buttons} ${buttonActive === "POSITIVE" ? active : ""}`}
								data-testid="positiveFeedback"
							>
								<ThumbsUpIcon />
							</button>
						)}
						<button
							onClick={() => sendFeedbackHandle("NEGATIVE")}
							className={`${buttons} ${buttonActive === "NEGATIVE" ? active : ""}`}
							data-testid="negativeFeedback"
						>
							<ThumbsDownIcon />
						</button>
					</div>
				</div>
			</div>
			<NegativeFeedbackModal
				isModalOpen={isModalOpen}
				setModalClose={modalCloseHandle}
				drawingStatus={drawingStatus}
				drawingId={Number(drawingId)}
				processingId={Number(processingId)}
				processingUrl={processingUrl}
				titleText="We’re sorry the response didn’t meet your expectations!"
			/>
		</>
	)
}
