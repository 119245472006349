import { useCallback, useEffect, useState } from "react"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { ButtonSecondary } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { Modal } from "components/ui/modals/Modal/Modal"
import { Input } from "components/ui/inputs/Input"
import { InputProvider } from "components/providers/Input.provider"
import { useAddKeyword, useKeywordsColors, useSpecificationKeywordsColors } from "hooks/keywords.hook"
import { Spin } from "components/ui/spin/Spin"
import { useMessage } from "hooks/message.hook"
import { Radio, Space } from "antd"
import { CheckmarkIcon } from "components/ui/icons/checkmark/CheckmarkIcon"
import { isAxiosError } from "helpers/utils.helper"
import type { TKeywordsColors, TAddNewKeywordData } from "types/keywords.type"
import style from "./AddKeywords.module.scss"

const {
	button,
	container,
	inputBlock,
	colorBlock,
	selectColor,
	colorItem,
	buttonBlock,
	spinnerContainer,
	keywordTypeBlock,
} = style

type Props = { refetchKeywords: () => void; refetchSpecificationKeywords: () => void }

export const AddKeywords = ({ refetchKeywords, refetchSpecificationKeywords }: Props) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [activeColorId, setActiveColorId] = useState<number | null>()
	const [keywordsData, setKeywordsData] = useState<TAddNewKeywordData>({
		keyword: "",
		colorId: null,
		type: "specification",
	})
	const [saveButtonSubmitable, setSaveButtonSubmitable] = useState(true)

	const { error: errorMessage, success: successMessage } = useMessage()

	const { mutate: addNewKeywordMutate, isPending: isAddnewKeywordPending } = useAddKeyword()
	const { data: keywordsColors, isPending: keywordsColorsIsPending } = useKeywordsColors()
	const { data: specificationKewordsColors, isPending: specificationKywordsColorsPending } =
		useSpecificationKeywordsColors()

	const selectColorHandle = useCallback((id: number) => {
		setActiveColorId(id)
		setKeywordsData((prev) => ({
			...prev,
			colorId: id,
		}))
	}, [])

	const addNewKeywordHandle = useCallback(() => {
		addNewKeywordMutate(keywordsData, {
			onSuccess: () => {
				setKeywordsData({ keyword: "", colorId: null, type: "" })
				setActiveColorId(null)
				setModalOpen(false)
				successMessage("Keyword created")
				refetchKeywords()
				refetchSpecificationKeywords()
			},
			onError: (e) => {
				if (isAxiosError(e)) {
					const status = e.response?.status
					if (status === 409) {
						errorMessage("Keyword already exists")
					} else {
						errorMessage("Something went wrong, please try again later")
					}
				}
			},
		})
	}, [keywordsData, addNewKeywordMutate, errorMessage, successMessage, refetchKeywords, refetchSpecificationKeywords])

	const closeModalHandle = useCallback(() => {
		setModalOpen(false)
		setKeywordsData({ keyword: "", colorId: null, type: "" })
		setActiveColorId(null)
	}, [])

	// Validate if all fields are filled then enable the save button
	useEffect(() => {
		const allFieldsFilled = Object.values(keywordsData).every((value) => value != "" && value != null)
		setSaveButtonSubmitable(allFieldsFilled)
	}, [keywordsData])

	return (
		<>
			<ButtonPrimary className={button} onClick={() => setModalOpen(true)}>
				Create new keyword
			</ButtonPrimary>
			<Modal title="Create keyword" isModalOpen={modalOpen} setModalClose={closeModalHandle}>
				{keywordsColorsIsPending || isAddnewKeywordPending || specificationKywordsColorsPending ? (
					<div className={spinnerContainer} data-testid="spinner">
						<Spin />
					</div>
				) : (
					<div className={container}>
						<div className={inputBlock}>
							<span>Name</span>
							<InputProvider>
								<Input
									value={keywordsData.keyword}
									onChange={(e) =>
										setKeywordsData((prev) => ({
											...prev,
											keyword: e.target.value,
										}))
									}
								/>
							</InputProvider>
						</div>
						<div className={keywordTypeBlock}>
							<span>Keyword type</span>
							<Radio.Group
								value={keywordsData.type}
								onChange={(e) => setKeywordsData((prev) => ({ ...prev, type: e.target.value }))}
							>
								<Space direction="vertical">
									<Radio value="specification">Specification</Radio>
									<Radio data-testid="drawing" value="drawing">
										Drawing
									</Radio>
								</Space>
							</Radio.Group>
						</div>
						<div className={colorBlock}>
							{keywordsData.type === "specification" ? (
								<>
									<span>Specification color</span>
									<div className={selectColor}>
										{specificationKewordsColors &&
											specificationKewordsColors.map(({ colorId, keywordColor }: TKeywordsColors) => (
												<div
													className={colorItem}
													style={{ backgroundColor: keywordColor }}
													key={colorId}
													onClick={() => selectColorHandle(colorId)}
													data-testid="colorItem"
												>
													{activeColorId === colorId && <CheckmarkIcon />}
												</div>
											))}
									</div>
								</>
							) : (
								<>
									<span>Color</span>
									<div className={selectColor}>
										{keywordsColors &&
											keywordsColors.map(({ colorId, keywordColor }: TKeywordsColors) => (
												<div
													className={colorItem}
													style={{ backgroundColor: keywordColor }}
													key={colorId}
													onClick={() => selectColorHandle(colorId)}
													data-testid="colorItem"
												>
													{activeColorId === colorId && <CheckmarkIcon />}
												</div>
											))}
									</div>
								</>
							)}
						</div>
						<div className={buttonBlock}>
							<ButtonSecondary onClick={closeModalHandle}>Cancel</ButtonSecondary>
							<ButtonPrimary onClick={addNewKeywordHandle} submittable={saveButtonSubmitable}>
								Save
							</ButtonPrimary>
						</div>
					</div>
				)}
			</Modal>
		</>
	)
}
