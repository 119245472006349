import { create } from "zustand"
import type {
	TUploadBarOpen,
	TUploadBarEnabled,
	TUploadFilesData,
	TPresignedUrl,
	TFilesToAbort,
} from "types/uploadFiles.type"

// Uploadbar states
export const useUploadbarOpenStore = create<TUploadBarOpen>((set) => ({
	isOpen: true,
	triggerOpen: () => set((state) => ({ isOpen: !state.isOpen })),
	setClosed: () => set(() => ({ isOpen: false })),
	setOpen: () => set(() => ({ isOpen: true })),
}))

export const useUploadbarEnabledStore = create<TUploadBarEnabled>((set) => ({
	isEnabled: false,
	setUploadbarEnabled: () => set(() => ({ isEnabled: true })),
	setUploadbarDisabled: () => set(() => ({ isEnabled: false })),
}))

// Presigned URL
export const useUploadPresignedUrlStore = create<TPresignedUrl>((set) => ({
	presignedUrl: undefined,
	uploadId: null,
	setPresignedUrl: (presignedUrl: string, uploadId: string) => set({ presignedUrl: presignedUrl, uploadId: uploadId }),
}))

// Uploading files
export const useUploadFilesDataStore = create<TUploadFilesData>((set) => ({
	allFiles: [],
	latestBatchIds: [], // Track the latest batch of file IDs being uploaded
	setFilesData: (fileData) =>
		set((state) => ({
			allFiles: [...state.allFiles.filter((file) => file.id !== fileData.id), fileData],
			latestBatchIds: state.latestBatchIds.includes(fileData.id)
				? state.latestBatchIds
				: [...state.latestBatchIds, fileData.id],
		})),
	setUpdateFilesData: (fileData) =>
		set((state) => ({
			allFiles: state.allFiles.map((file) => (file.id === fileData.id ? { ...file, ...fileData } : file)),
		})),
	setClearFilesData: () => set({ allFiles: [], latestBatchIds: [] }),
	setLatestBatchIds: (ids: string[]) => set({ latestBatchIds: ids }),
	resetLatestBatch: () => set({ latestBatchIds: [] }),
}))

// Abort uploading
export const useFilesToAbortStore = create<TFilesToAbort>((set) => ({
	filesToAbort: {},
	setFileController: (id, controller) =>
		set((state) => ({
			filesToAbort: { ...state.filesToAbort, [id]: controller },
		})),
	resetIdsToAbort: () =>
		set(() => ({
			filesToAbort: {},
		})),
	abortAllFiles: () =>
		set((state) => {
			Object.values(state.filesToAbort).forEach((controller) => {
				controller.abort()
			})
			return { ...state, filesToAbort: {} }
		}),
}))
