import { create } from "zustand"

interface PDFInstance {
	Core: {
		documentViewer: {
			getPageCount: () => number
			setCurrentPage: (pageNum: number) => void
		}
	}
}

type ViewerStore = {
	instance: PDFInstance | null
	setInstance: (instance: PDFInstance) => void
	navigateToPage: (pageNumber: string, showError: (message: string) => void) => void
}

// Add viewer instance and navigation function
export const useViewerStore = create<ViewerStore>((set, get) => ({
	instance: null,
	setInstance: (instance) => set({ instance }),
	navigateToPage: async (pageNumber: string, showError) => {
		const instance = get().instance
		if (!instance) {
			showError("PDF viewer not initialized")
			return
		}

		try {
			const docViewer = instance.Core.documentViewer
			const pageNum = parseInt(pageNumber, 10)

			// Validate page number
			if (isNaN(pageNum)) {
				showError(`Invalid page number: ${pageNumber}`)
				return
			}

			// Get total pages and validate
			const totalPages = docViewer.getPageCount()
			if (pageNum < 1 || pageNum > totalPages) {
				showError(`Reference to page ${pageNum} found, but document only has ${totalPages} pages`)
				return
			}

			docViewer.setCurrentPage(pageNum)
		} catch (error) {
			showError(`Navigation error: ${error}`)
		}
	},
}))
