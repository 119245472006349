import { useMutation, useQuery } from "@tanstack/react-query"
import keywordsService from "services/keywords.service"

export const useKeywords = () => {
	return useQuery({
		queryKey: ["keywords"],
		queryFn: () => keywordsService.getKeywords(),
		select: ({ data }) => data,
	})
}

export const useDeleteKeyword = () => {
	return useMutation({
		mutationFn: (id: number) => keywordsService.deleteKeyword(id),
	})
}

export const useAddKeyword = () => {
	return useMutation({
		mutationFn: ({ keyword, colorId, type }: { keyword: string; colorId: null | number; type: string }) =>
			keywordsService.addKeyword(keyword, colorId, type),
	})
}

export const useKeywordsColors = () => {
	return useQuery({
		queryKey: ["keywordsColors"],
		queryFn: () => keywordsService.getKeywordsColors(),
		select: ({ data }) => data.colors,
	})
}

export const useSpecificationKeywords = () => {
	return useQuery({
		queryKey: ["specificationKeywords"],
		queryFn: () => keywordsService.getSpecificationKeywords(),
		select: ({ data }) => data,
	})
}

export const useSpecificationKeywordsColors = () => {
	return useQuery({
		queryKey: ["specificationKeywordsColors"],
		queryFn: () => keywordsService.getSpecificationKeywordsColors(),
		select: ({ data }) => data.colors,
	})
}
