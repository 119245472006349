export const CopyIcon = () => {
	return (
		<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.66634 2.29167H2.74967C2.50656 2.29167 2.2734 2.38824 2.10149 2.56015C1.92958 2.73206 1.83301 2.96522 1.83301 3.20833V8.70833C1.83301 8.95145 1.92958 9.18461 2.10149 9.35651C2.2734 9.52842 2.50656 9.625 2.74967 9.625H8.24967C8.49279 9.625 8.72595 9.52842 8.89786 9.35651C9.06976 9.18461 9.16634 8.95145 9.16634 8.70833V3.20833C9.16634 2.96522 9.06976 2.73206 8.89786 2.56015C8.72595 2.38824 8.49279 2.29167 8.24967 2.29167H7.33301M3.66634 2.29167C3.66634 2.04855 3.76292 1.81539 3.93483 1.64349C4.10674 1.47158 4.33989 1.375 4.58301 1.375H6.41634C6.65946 1.375 6.89261 1.47158 7.06452 1.64349C7.23643 1.81539 7.33301 2.04855 7.33301 2.29167M3.66634 2.29167C3.66634 2.53478 3.76292 2.76794 3.93483 2.93985C4.10674 3.11176 4.33989 3.20833 4.58301 3.20833H6.41634C6.65946 3.20833 6.89261 3.11176 7.06452 2.93985C7.23643 2.76794 7.33301 2.53478 7.33301 2.29167"
				stroke="black"
				strokeWidth="0.916667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
