import { useState, useEffect } from "react"

export const useDebounce = (value: string, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		// Don't set up a timer for empty strings or strings shorter than minimum length
		if (value.length === 0) {
			setDebouncedValue("")
			return
		}

		// Set up a timer
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		// Clear the timer if the value changes (e.g. user types more)
		return () => {
			clearTimeout(handler)
		}
	}, [value, delay])

	return debouncedValue
}
