import { useEffect, useState } from "react"
import { InputSearch } from "components/ui/inputs/InputSearch"
import { H1 } from "components/ui/typography/titles/Titles"
import { UploadFiles } from "../UploadFiles/UploadFiles"
import { useFillesTableStore } from "store/table.store"
import { useDebounce } from "hooks/debounce.hook"
import type { TOnChangeInput } from "types/default.type"
import style from "./TopTableBar.module.scss"

const { TopTableBarStyle, wrapper, uploadFiles, inputSearch } = style

type Props = { totalRecords: number }

export const TopTableBar = ({ totalRecords }: Props) => {
	const [search, setSearch] = useState("")
	const debouncedSearch = useDebounce(search, 500)
	const { setFilter, setCurrentPage } = useFillesTableStore()

	useEffect(() => {
		// Set the filter when search is more than 2 characters
		if (debouncedSearch.length > 2) {
			setFilter({ fileName: debouncedSearch })
			setCurrentPage(1)
		} else if (debouncedSearch.length === 0) {
			// Reset the filter when search is cleared
			setFilter({ fileName: "" })
		}
	}, [debouncedSearch, setFilter, setCurrentPage])

	const searchHandle = (e: TOnChangeInput) => {
		const value = e.target.value
		setSearch(value)
	}

	return (
		<div className={TopTableBarStyle}>
			<H1>
				All files <sup data-testid="recordsSup">{totalRecords && totalRecords}</sup>
			</H1>
			<div className={wrapper}>
				<div className={inputSearch}>
					<InputSearch onChange={(e) => searchHandle(e)} width={320} />
				</div>
				<div className={uploadFiles}>
					<UploadFiles />
				</div>
			</div>
		</div>
	)
}
