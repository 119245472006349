import { Table as TableAnt } from "antd"
import { TableObjectsProvider } from "components/providers/Table.provider"
import type { ColumnsType } from "antd/es/table"
import type { ExpandableConfig } from "antd/es/table/interface"
import { MouseEvent } from "react"

type Props<ColumnsDataType> = {
	columns: ColumnsType<ColumnsDataType>
	data: ColumnsDataType[]
	className?: string
	scrollY?: number
	expandable?: ExpandableConfig<ColumnsDataType>
	isLoading?: boolean
	onRow?: () => {
		onMouseEnter?: (event: MouseEvent<HTMLTableRowElement>) => void
		onMouseLeave?: (event: MouseEvent<HTMLTableRowElement>) => void
	}
}

export const TableConfiguration = <ColumnsDataType extends object>({
	columns,
	data,
	className,
	scrollY,
	expandable,
	isLoading,
	onRow,
}: Props<ColumnsDataType>) => {
	return (
		<TableObjectsProvider>
			<TableAnt
				className={className}
				columns={columns}
				dataSource={data}
				pagination={false}
				scroll={{ y: scrollY }}
				expandable={expandable}
				onRow={onRow}
				loading={isLoading}
			/>
		</TableObjectsProvider>
	)
}
