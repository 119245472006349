import { Space, Switch } from "antd"
import { ButtonOptions } from "components/ui/buttons/ButtonOptions/ButtonOptions"
import { Dropdown } from "components/ui/dropdown/Dropdown"
import { InstructionsTableUtils } from "./InstructionsTableUtils"
import type { ColumnsType } from "antd/es/table"
import type { IInstructionsTable } from "types/table.type"
import style from "./InstructionsTable.module.scss"

const { sectionNumber } = style

type Props = { setEditInstructionModalOpen: () => void; refetchInstructions: () => void }

export const InstructionsTableColumns = ({ setEditInstructionModalOpen, refetchInstructions }: Props) => {
	const { dropdownHandle, onChangeSwitecherHandle } = InstructionsTableUtils({
		setEditInstructionModalOpen,
		refetchInstructions,
	})

	const columns: ColumnsType<IInstructionsTable> = [
		{
			title: "SHORT FORM",
			dataIndex: "question",
			width: "70%",
		},
		{
			title: "SECTION NUMBER",
			dataIndex: "sections",
			render: (item) => (
				<div className={sectionNumber}>
					{item.map((subItem: string, index: number) => (
						<span key={index}>{subItem}</span>
					))}
				</div>
			),
		},
		{
			title: "ACTIVE",
			dataIndex: "active",
			render: (item) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Switch
						defaultChecked={item.status === "active" ? true : false}
						onChange={(checked) => onChangeSwitecherHandle(item.questionId, checked)}
					/>
				</div>
			),
		},
		{
			title: "",
			dataIndex: "action",
			render: (item) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown items={dropdownHandle(item)}>
						<Space>
							<ButtonOptions />
						</Space>
					</Dropdown>
				</div>
			),
		},
	]

	return columns
}
