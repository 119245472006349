import style from "./ConfigurationTabs.module.scss"
import { useConfigurationTabsStore } from "store/configuration.store"

const { configurationTabs, ul, li, active } = style

export const ConfigurationTabs = () => {
	const { setConfigurationTabsIndex, configurationTabsIndex } = useConfigurationTabsStore()

	const listItemHandle = (index: number) => {
		setConfigurationTabsIndex(index)
	}

	return (
		<menu className={configurationTabs}>
			<ul className={ul}>
				<li className={`${li} ${configurationTabsIndex === 0 ? active : ""}`} onClick={() => listItemHandle(0)}>
					<span>Keywords</span>
				</li>
				<li className={`${li} ${configurationTabsIndex === 1 ? active : ""}`} onClick={() => listItemHandle(1)}>
					<span>Objects</span>
				</li>
				<li className={`${li} ${configurationTabsIndex === 2 ? active : ""}`} onClick={() => listItemHandle(2)}>
					<span>AI Instructions</span>
				</li>
			</ul>
		</menu>
	)
}
