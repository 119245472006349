export const ThumbsDownIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				d="M15.5296 12.7863C16.0049 12.7863 16.2425 12.7863 16.4369 12.7315C16.9254 12.5936 17.3074 12.2118 17.4452 11.7231C17.5 11.5288 17.5 11.2912 17.5 10.816C17.5 8.83468 17.5 6.77589 17.5 4.7945C17.5 4.31933 17.5 4.08174 17.4452 3.88739C17.3074 3.39878 16.9254 3.0169 16.4369 2.87905C16.2425 2.82422 16.0049 2.82422 15.5296 2.82422C15.0543 2.82422 14.8167 2.82422 14.6224 2.87905C14.1337 3.0169 13.7519 3.39878 13.614 3.88739C13.5591 4.08174 13.5591 4.31933 13.5591 4.7945C13.5591 6.77589 13.5591 8.83468 13.5591 10.816C13.5591 11.2912 13.5591 11.5288 13.614 11.7231C13.7519 12.2118 14.1337 12.5936 14.6224 12.7315C14.8167 12.7863 15.0543 12.7863 15.5296 12.7863Z"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.5596 11.8857C12.3549 13.4435 11.1597 15.0091 9.94508 16.5591C9.56025 17.0501 8.88643 17.2976 8.28153 17.1216C6.93233 16.7295 7.27676 14.3366 7.6296 13.0676C7.89124 12.1265 7.57312 11.8001 6.80702 11.8001C5.13177 11.8001 4.33576 11.8001 3.7655 11.4574C3.24392 11.144 2.86095 10.644 2.69416 10.0588C2.34638 8.83864 2.46617 6.51429 3.06218 4.89428C3.46484 3.79983 4.60055 3.04715 5.58425 2.93797C6.91265 2.79054 8.76676 2.84372 10.1667 2.92145C11.4531 2.99287 12.6511 3.56519 13.5596 4.47869"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
