import { useState } from "react"
import { Modal } from "components/ui/modals/Modal/Modal"
import { useModelNumbersModalStore } from "store/modal.store"
import { ButtonSecondary } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { EditableModelNumbers } from "./layout/EditableModelNumbers/EditableModelNumbers"
import { useUpdateModelNumbers } from "hooks/objectsCategories.hook"
import { Spin } from "components/ui/spin/Spin"
import { useMessage } from "hooks/message.hook"
import { AxiosError } from "axios"
import style from "./ModelNumbersModal.module.scss"

const { modelNumbersModal, title, text, buttons, modelNumbersBlock, textWarning } = style

type Props = {
	refetchObjectCategories: () => void
}

export const ModelNumbersModal = ({ refetchObjectCategories }: Props) => {
	const {
		isModelNumbersModalOpen,
		setModelNumbersModalClose,
		objectName,
		modelNumbers,
		categoryId,
		clearModelNumbers,
		clearInitilaModelNumbers,
	} = useModelNumbersModalStore()

	const { mutate: updateModelNumbersMutate, isPending: isUpdateModelNumbersPending } = useUpdateModelNumbers()
	const { success: successMessage, error: errorMessage } = useMessage()

	const [hasChanges, setHasChanges] = useState(false)

	const onClickSaveButton = () => {
		updateModelNumbersMutate(
			{ modelNumbers, categoryId },
			{
				onSuccess: () => {
					refetchObjectCategories()
					setModelNumbersModalClose()
					clearModelNumbers()
					clearInitilaModelNumbers()
					successMessage(`Model Numbers for ${objectName} is updated`)
				},
				onError: (e) => {
					if (e instanceof AxiosError) {
						const status = e.response?.status
						const reponseMessage = e.response?.data.model_numbers[0]
						if (status === 400) {
							errorMessage(reponseMessage)
						} else {
							errorMessage("Something went wrong, please try again later")
						}
					}
				},
			},
		)
	}

	const onLickCancelButton = () => {
		setModelNumbersModalClose()
		clearModelNumbers()
		clearInitilaModelNumbers()
	}

	return (
		<Modal width={448} isModalOpen={isModelNumbersModalOpen} setModalClose={setModelNumbersModalClose}>
			<div className={modelNumbersModal}>
				<span className={title}>Change model numbers</span>
				<p className={text}>Below you can edit model numbers for {objectName}</p>
				<div className={modelNumbersBlock} data-testid="tagInput">
					<span>Model numbers</span>
					<span className={`${text} ${textWarning}`}>Type the Model Number and press enter to add</span>
					<EditableModelNumbers setHasChanges={setHasChanges} />
					<span className={`${text} ${textWarning}`}>All Model Numbers must be unique within all objects</span>
				</div>
				<div className={buttons}>
					<ButtonSecondary onClick={onLickCancelButton}>Cancel</ButtonSecondary>
					<ButtonPrimary onClick={onClickSaveButton} submittable={!isUpdateModelNumbersPending && hasChanges}>
						{isUpdateModelNumbersPending ? (
							<div data-testid="spin">
								<Spin />
							</div>
						) : (
							"Save"
						)}
					</ButtonPrimary>
				</div>
			</div>
		</Modal>
	)
}
