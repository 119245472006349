import { useState } from "react"
import { ThumbsUpIcon } from "components/ui/icons/iaAgent/ThumbsUpIcon"
import { ThumbsDownIcon } from "components/ui/icons/iaAgent/ThumbsDownIcon"
import { useSendFeedback } from "hooks/AIAgent.hook"
import { useMessage } from "hooks/message.hook"
import { NegativeFeedbackModal } from "../NagativeFeedbackModal/NegativeFeedbackModal"
import { Spin } from "components/ui/spin/Spin"
import style from "./FeedbackForResponse.module.scss"

const { chatBottomBar, feedbackBlock, feedBackIcons, buttons, active } = style

type Props = { langsmithTraceId: string | undefined; drawingId: number; questionId: number | undefined }

export const FeedbackForResponse = ({ langsmithTraceId, drawingId, questionId }: Props) => {
	const [buttonActive, setButtonActive] = useState("")
	const { mutate: sendFeedbackMutation, isPending: sendFeedbackPending } = useSendFeedback()
	const { success: successMessage, error: errorMessage } = useMessage()
	const [isModalOpen, setModalOpen] = useState(false)

	const sendFeedbackHandle = (feedbackType: string) => {
		// Open modal if feedback is negative and return
		if (feedbackType === "NEGATIVE") {
			setModalOpen(true)
			return
		}

		sendFeedbackMutation(
			{
				drawingId,
				questionId,
				langsmithTraceId: langsmithTraceId,
				type: feedbackType,
			},
			{
				onSuccess: () => {
					successMessage("Feedback sent")
					setButtonActive("POSITIVE")
				},
				onError: () => {
					errorMessage("Failed to send feedback, please try again")
					setButtonActive("")
				},
			},
		)
	}

	return (
		<>
			<div className={chatBottomBar}>
				<div className={feedbackBlock}>
					<span>Good answer?</span>
					<div className={feedBackIcons}>
						{/* Show spinner when positive feedback posting */}
						{sendFeedbackPending ? (
							<Spin />
						) : (
							<button
								onClick={() => sendFeedbackHandle("POSITIVE")}
								className={`${buttons} ${buttonActive === "POSITIVE" ? active : ""}`}
								data-testid="positiveFeedback"
							>
								<ThumbsUpIcon />
							</button>
						)}
						<button
							onClick={() => sendFeedbackHandle("NEGATIVE")}
							className={`${buttons} ${buttonActive === "NEGATIVE" ? active : ""}`}
							data-testid="negativeFeedback"
						>
							<ThumbsDownIcon />
						</button>
					</div>
				</div>
			</div>
			<NegativeFeedbackModal
				isModalOpen={isModalOpen}
				setModalClose={() => setModalOpen(false)}
				drawingId={drawingId}
				questionId={questionId}
				langsmithTraceId={langsmithTraceId}
				setButtonActive={setButtonActive}
			/>
		</>
	)
}
