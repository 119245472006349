import { useState } from "react"
import { H2 } from "components/ui/typography/titles/Titles"
import { KeywordsByAlphabet } from "./layout/KeywordsByAlphabet/KeywordsByAlphabet"
import { AddKeywords } from "./layout/AddKeywords/AddKeywords"
import { useKeywords, useSpecificationKeywords } from "hooks/keywords.hook"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { Segmented } from "components/ui/segmented/Segmented"
import style from "./Keywords.module.scss"

const { keywords, top, skeletonContainer, segmented, title } = style

export const Keywords = () => {
	const { data: keywordsData, isPending: isKeywordsPending, refetch: refetchKeywords } = useKeywords()
	const { data: specificationKeywords, refetch: refetSpecificationKeywords } = useSpecificationKeywords()
	const [segmentedValue, setSegmentedValue] = useState<string | number>("Specification keywords")

	const segmentChangeHandle = (value: string | number) => {
		setSegmentedValue(value)
	}

	if (isKeywordsPending) {
		return (
			<div className={skeletonContainer}>
				<Skeleton rows={12} />
			</div>
		)
	}

	return (
		<div className={keywords}>
			<div className={top}>
				<H2 className={title}>All Keywords</H2>
				<AddKeywords refetchKeywords={refetchKeywords} refetchSpecificationKeywords={refetSpecificationKeywords} />
			</div>
			<div className={segmented}>
				<Segmented
					onChange={(value) => segmentChangeHandle(value)}
					options={["Specification keywords", "Drawing keywords"]}
				/>
			</div>
			{/* <InputSearch placeholder="Search keyword" disabled={true} /> */}
			{segmentedValue === "Specification keywords" && (
				<KeywordsByAlphabet
					keywords={specificationKeywords}
					refetchSpecificationKeywords={refetSpecificationKeywords}
					refetchKeywords={refetchKeywords}
				/>
			)}
			{segmentedValue === "Drawing keywords" && (
				<KeywordsByAlphabet
					keywords={keywordsData}
					refetchKeywords={refetchKeywords}
					refetchSpecificationKeywords={refetSpecificationKeywords}
				/>
			)}
		</div>
	)
}
