import { ChangeEvent } from "react"
import { OnChange } from "@monaco-editor/react"
import { Form, Input } from "antd"
import { Editor } from "components/ui/editor/Editor"
import { NumbersTextarea } from "components/ui/NumbersTextarea/NumbersTextarea"
import style from "./InstructionsForm.module.scss"
import { AddModelObjectProvider } from "components/providers/Forms.provider"

const { wrapper, block } = style

type Props = {
	editorValue: string
	question: string
	numbers: string[]
	setNumbers: (numbers: string[]) => void
	onEditorChange: OnChange | undefined
	onQuestionChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const InstructionsForm = ({
	editorValue,
	onEditorChange,
	question,
	onQuestionChange,
	numbers,
	setNumbers,
}: Props) => {
	return (
		<AddModelObjectProvider>
			<Form>
				<div className={wrapper}>
					<div className={block}>
						<span>Short question form</span>
						<Form.Item>
							<Input size="large" onChange={onQuestionChange} value={question} />
						</Form.Item>
					</div>
					<div className={block}>
						<span>Instruction</span>
						<Form.Item>
							<Editor value={editorValue} isReadOnly={false} height={400} onEditorChange={onEditorChange} />
						</Form.Item>
					</div>
					<div className={block}>
						<span>Section numbers</span>
						<Form.Item>
							<NumbersTextarea
								setNumbers={setNumbers}
								numbers={numbers}
								helperText="Type the section number and press enter to add"
							/>
						</Form.Item>
					</div>
				</div>
			</Form>
		</AddModelObjectProvider>
	)
}
