import { axiosInstance } from "api/axios.instance"

class KeywordsService {
	static BASE_URL = "/keywords"

	async getKeywords() {
		return axiosInstance.get(`${KeywordsService.BASE_URL}/drawing/`)
	}

	async deleteKeyword(id: number) {
		return axiosInstance.delete(`${KeywordsService.BASE_URL}/${id}`)
	}

	async addKeyword(keyword: string, colorId: number | null, type: string) {
		return axiosInstance.post(`${KeywordsService.BASE_URL}/`, {
			keyword,
			colorId,
			type,
		})
	}

	async getKeywordsColors() {
		return axiosInstance.get(`${KeywordsService.BASE_URL}/colors`)
	}

	async getSpecificationKeywords() {
		return axiosInstance.get(`${KeywordsService.BASE_URL}/specification/`)
	}

	async getSpecificationKeywordsColors() {
		return axiosInstance.get(`${KeywordsService.BASE_URL}/colors/specification/`)
	}
}

export default new KeywordsService()
