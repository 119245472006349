import AIAgentService from "services/AIAgent.service"
import { useMutation, useQuery } from "@tanstack/react-query"
import { TInstructionsData, TInstructionStatus } from "types/aiAget.type"

export const useGetInstructions = () => {
	return useQuery({
		queryKey: ["getInstructions"],
		queryFn: () => AIAgentService.getInstructions(),
		select: ({ data }) => data,
	})
}

export const useGetQuestions = (drawingId: number) => {
	return useQuery({
		queryKey: ["getQuestions", drawingId],
		queryFn: () => AIAgentService.getQuestions(drawingId),
		select: ({ data }) => data,
		enabled: !!drawingId,
		refetchInterval: (query) => (query.state.data?.data.status === "ongoing" ? 5000 : false),
	})
}

export const useAskQuestion = () => {
	return useMutation({
		mutationKey: ["askQuestion"],
		mutationFn: ({ drawingId, questionId, subject }: { drawingId: number; questionId: number; subject: string }) =>
			AIAgentService.askQuestion(drawingId, questionId, subject),
	})
}

export const useSendFeedback = () => {
	return useMutation({
		mutationKey: ["sendFeedback"],
		mutationFn: ({
			drawingId,
			questionId,
			langsmithTraceId,
			type,
			feedback,
		}: {
			drawingId: number
			questionId: number | undefined
			langsmithTraceId: string | undefined
			type: string
			feedback?: string
		}) => AIAgentService.sendFeedback(drawingId, questionId, langsmithTraceId, type, feedback),
	})
}

export const useAddInstruction = () => {
	return useMutation({
		mutationKey: ["addInstruction"],
		mutationFn: (instruction: TInstructionsData) => AIAgentService.addInstruction(instruction),
	})
}

export const useUpdateInstruction = () => {
	return useMutation({
		mutationKey: ["updateInstruction"],
		mutationFn: ({ question, prompt, sections, questionId }: TInstructionsData) =>
			AIAgentService.updateInstruction({ question, prompt, sections, questionId }),
	})
}

export const useDeleteInstruction = () => {
	return useMutation({
		mutationKey: ["deleteInstruction"],
		mutationFn: (questionId: number) => AIAgentService.deleteInstruction(questionId),
	})
}

export const useUpdateInstructionStatus = () => {
	return useMutation({
		mutationKey: ["updateInstructionStatus"],
		mutationFn: ({ questionId, state }: { questionId: number; state: TInstructionStatus }) =>
			AIAgentService.updateInstructionStatus(questionId, state),
	})
}
