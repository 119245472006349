import { SidebarLeft } from "components/ui/SidebarLeft/SidebarLeft"
import { ConfigurationTabs } from "./layout/ConfigurationTabs/ConfigurationTabs"
import { ConfigurationContent } from "./layout/ConfigurationContent/ConfigurationContent"
import style from "./PageConfiguration.module.scss"

const { pageConfiguration, content } = style

export const PageConfiguration = () => {
	return (
		<section className={pageConfiguration}>
			<SidebarLeft>
				<ConfigurationTabs />
			</SidebarLeft>
			<div className={content}>
				<ConfigurationContent />
			</div>
		</section>
	)
}
