import { useRef } from "react"
import { Modal } from "components/ui/modals/Modal/Modal"
import { useOpenFilePreview } from "hooks/openFilePreview.hook"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { AIAgent } from "./layout/AIAget/AIAgent"
import { AIAgentWhiteIcon } from "components/ui/icons/iaAgent/AIAgentWhiteIcon"
import style from "./DocumentModal.module.scss"
import { useChatStore } from "store/aiAgent.store"

const { container, pdfViewerContainer, aiAgentButton, aiAgentContainer, aiAgentContainerOpen, pdfViewerContainerOpen } =
	style

type Props = {
	isFileModalOpen: boolean
	setFileModalClose: () => void
	previewLink: string | undefined
}

export const DocumentModal = ({ isFileModalOpen, setFileModalClose, previewLink }: Props) => {
	const { isChatOpen, openChat } = useChatStore()

	// Use the custom hook to open file preview
	const viewer = useRef(null)
	useOpenFilePreview({ fileLink: previewLink, previewRef: viewer })

	const closeModalHandle = () => {
		setFileModalClose()
	}

	return (
		<Modal isModalOpen={isFileModalOpen} setModalClose={closeModalHandle} width="100%">
			<div className={container}>
				<div className={`${pdfViewerContainer} ${isChatOpen ? pdfViewerContainerOpen : ""}`} ref={viewer}>
					{!isChatOpen ? (
						<ButtonPrimary onClick={() => openChat()} className={aiAgentButton}>
							<AIAgentWhiteIcon />
							AI Agent
						</ButtonPrimary>
					) : null}
				</div>
				<div className={`${aiAgentContainer} ${isChatOpen ? aiAgentContainerOpen : ""}`}>
					<AIAgent />
				</div>
			</div>
		</Modal>
	)
}
