import { axiosInstance } from "api/axios.instance"
import { TProcessingGeneralFeedbackQuery } from "types/drawer.type"

type DrawingsQueryParams = {
	page: number
	owner?: string | null
	filterByName?: string
}

class DrawingsService {
	static BASE_URL = "/drawings"

	async getDrawings(page: number, owner: string | undefined, fileName: string | undefined) {
		const params: DrawingsQueryParams = { page }

		if (owner !== "all") {
			params.owner = owner
		}

		if (fileName) {
			params.filterByName = fileName
		}

		return axiosInstance.get(`${DrawingsService.BASE_URL}/`, { params })
	}

	async getDrawing(id: number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/`)
	}

	async getDrawingWithProcessings(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/`)
	}

	async getDocuments(drowingId: number, processingId: string | number | null, drawingStatus: string) {
		const urlWithoutProcessing = `${DrawingsService.BASE_URL}/${drowingId}/documents`
		const urlWithProcessings = `${DrawingsService.BASE_URL}/${drowingId}/processings/${processingId}/documents`
		const url = drawingStatus === "uploaded" ? urlWithoutProcessing : urlWithProcessings

		return axiosInstance.get(url)
	}

	async getDrawingKeywords(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/keywords/drawing/`)
	}

	async getSpecificationKeywords(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(
			`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/keywords/specification/`,
		)
	}

	async getDrawingKeywordsConfig(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(
			`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/key-phrases-config/drawing/`,
		)
	}

	async getDrawingSpecificationKeywordsConfig(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(
			`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/key-phrases-config/specification/`,
		)
	}

	async getDrawingObjects(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/objects-found`)
	}

	async getDrawingObjectsConfig(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/objects-config/`)
	}

	async delDrawing(id: number | undefined) {
		return axiosInstance.delete(`${DrawingsService.BASE_URL}/${id}/`)
	}

	async getProcessingRuns(id: number) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/processings/`)
	}

	async duplicateDrawing(id: number) {
		return axiosInstance.post(`${DrawingsService.BASE_URL}/${id}/duplicate/`)
	}

	async postProcessingGeneralFeedback({ formData, drawingId, processingId }: TProcessingGeneralFeedbackQuery) {
		return axiosInstance.post(
			`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/generalFeedback/`,
			formData,
			{
				headers: { "Content-Type": "multipart/form-data" },
				timeout: 0,
			},
		)
	}

	async postGeneralFeedback(formData: FormData, drawingId: number) {
		return axiosInstance.post(`${DrawingsService.BASE_URL}/${drawingId}/generalFeedback/`, formData, {
			headers: { "Content-Type": "multipart/form-data" },
			timeout: 0,
		})
	}

	async getDrawingVersions(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/sw-versions`)
	}
}

export default new DrawingsService()
