import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Form, Input } from "antd"
import { H4 } from "components/ui/typography/titles/Titles"
import { Select } from "components/ui/select/Select"
import { NumbersTextarea } from "components/ui/NumbersTextarea/NumbersTextarea"
import { useCategories } from "hooks/categories.hook"
import { useAddDualPartCategoryStore } from "store/modal.store"
import type { TTopLevelCategory } from "types/drawer.type"
import style from "./DualPartObject.module.scss"
import { IAddObjectMethods } from "types/configuration.type"
import { AddModelObjectProvider } from "components/providers/Forms.provider"

const { addModelObjectForm, block, label, description, endOfSection, title, select, startOfSection } = style

type Props = {
	objectCategoriesData: TTopLevelCategory[]
}

type ModelNumberType = "left" | "right" | "whole"

export const DualPartObject = forwardRef<IAddObjectMethods, Props>(({ objectCategoriesData }: Props, ref) => {
	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	const [selectedTopVevelCategory, setSelectedTopLevelCategory] = useState<string | number | undefined>()
	const [modelNumbers, setModelNumbers] = useState({
		left: [],
		right: [],
		whole: [],
	})

	const { topLevelCategories, superCategories } = useCategories(objectCategoriesData, selectedTopVevelCategory)
	const { setFormValues, category } = useAddDualPartCategoryStore()

	// Set form values
	useEffect(() => {
		setFormValues({
			name: values?.name,
			label: values?.label,
			topLevelCategory: values?.topLevelCategory,
			superCategory: values?.superCategory,
			leftModelNumbers: modelNumbers.left,
			rightModelNumbers: modelNumbers.right,
			wholeModelNumbers: modelNumbers.whole,
		})
	}, [modelNumbers, values, setFormValues])

	// Change model numbers
	const updateModelNumbers = (key: ModelNumberType) => (newModelNumbers: string[]) => {
		setModelNumbers((prev) => ({ ...prev, [key]: newModelNumbers }))
	}

	// Change top level category
	const onChangeTopLevelCategoryHandle = (value: string | number) => {
		setSelectedTopLevelCategory(value)
		form.resetFields(["superCategory"])
	}

	useImperativeHandle(ref, () => ({
		resetForm: () => form.resetFields(),
	}))

	return (
		<AddModelObjectProvider>
			<Form form={form} size="large">
				<div className={addModelObjectForm}>
					<div className={block}>
						<span className={label}>Name</span>
						<span className={description}>Without [r], [l], or [w]</span>
						<Form.Item name="name" rules={[{ required: true, message: "Name is required" }]}>
							<Input />
						</Form.Item>
					</div>
					<div className={`${block} ${endOfSection}`}>
						<span className={label}>Label</span>
						<span className={description}>
							<span className={description}>Used in train set, without [r], [l], or [w]</span>
							<Form.Item
								name="label"
								rules={[
									{ required: true, message: "Label is reuired" },
									{ pattern: /^l:/, message: 'Label must start with ":l"' },
								]}
							>
								<Input />
							</Form.Item>
						</span>
					</div>
					<div className={block}>
						<H4 className={title}>Categories</H4>
						<span className={label}>Top level category</span>
						<Form.Item name="topLevelCategory" rules={[{ required: true, message: "Top level category is required" }]}>
							<Select
								placeholder="Select category"
								allowClear={false}
								variant="outlined"
								options={topLevelCategories || []}
								onChange={onChangeTopLevelCategoryHandle}
								className={select}
							/>
						</Form.Item>
					</div>
					<div className={block}>
						<span className={label}>Super category</span>
						<Form.Item name="superCategory" rules={[{ required: true, message: "Super category is required" }]}>
							<Select
								placeholder="Select category"
								variant="outlined"
								allowClear={false}
								className={select}
								options={superCategories || []}
							/>
						</Form.Item>
					</div>
					<div className={`${block} ${startOfSection}`}>
						<H4 className={title}>Model numbers</H4>
						<span className={label}>Left part</span>
						<NumbersTextarea
							numbers={category?.leftModelNumbers}
							setNumbers={updateModelNumbers("left")}
							helperText="Type the model number and press enter to add"
						/>
					</div>
					<div className={block}>
						<span className={label}>Right part</span>
						<NumbersTextarea
							numbers={category?.rightModelNumbers}
							setNumbers={updateModelNumbers("right")}
							helperText="Type the model number and press enter to add"
						/>
					</div>
					<div className={block}>
						<span className={label}>Whole object</span>
						<NumbersTextarea
							numbers={category?.wholeModelNumbers}
							setNumbers={updateModelNumbers("whole")}
							helperText="Type the model number and press enter to add"
						/>
					</div>
				</div>
			</Form>
		</AddModelObjectProvider>
	)
})
