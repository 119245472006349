import { useMutation } from "@tanstack/react-query"
import drawingService from "services/drawing.service"
import { useMessage } from "./message.hook"
import { TGeneralFeedbackQuery, TProcessingGeneralFeedbackQuery } from "types/drawer.type"

interface UseFeedbackOptions {
	onSuccess?: () => void
	onError?: () => void
}

export const useFeedbackMutation = (options?: UseFeedbackOptions) => {
	const { success, error } = useMessage()
	const postProcessingMutation = useMutation({
		mutationKey: ["processingGeneralFeedback"],
		mutationFn: ({ formData, drawingId, processingId }: TProcessingGeneralFeedbackQuery) =>
			drawingService.postProcessingGeneralFeedback({ formData, drawingId, processingId }),
		onSuccess: () => {
			success("Thank you for your feedback!")
			options?.onSuccess?.()
		},
		onError: () => {
			error("Something went wrong, please try again later")
			options?.onError?.()
		},
	})

	const postGeneralMutation = useMutation({
		mutationKey: ["generalFeedback"],
		mutationFn: ({ formData, drawingId }: TGeneralFeedbackQuery) =>
			drawingService.postGeneralFeedback(formData, drawingId),
		onSuccess: () => {
			success("Thank you for your feedback!")
			options?.onSuccess?.()
		},
		onError: () => {
			error("Something went wrong, please try again later")
			options?.onError?.()
		},
	})

	const submitFeedback = (params: TProcessingGeneralFeedbackQuery | TGeneralFeedbackQuery, drawingStatus: string) => {
		if (drawingStatus === "processed" || drawingStatus === "failed") {
			postProcessingMutation.mutate(params as TProcessingGeneralFeedbackQuery)
		} else {
			postGeneralMutation.mutate(params as TGeneralFeedbackQuery)
		}
	}

	return {
		submitFeedback,
		isFeedbackPending: postProcessingMutation.isPending || postGeneralMutation.isPending,
		isError: postProcessingMutation.isError || postGeneralMutation.isError,
	}
}
