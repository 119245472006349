import { useState } from "react"
import { InputBorderless } from "../inputs/InputBorderless"
import { Tag } from "../tag/Tag"
import { CloseIcon } from "../icons/close/CloseIcon"
import { TOnChangeInput } from "types/default.type"
import style from "./NumbersTextarea.module.scss"

const { modelNumbersTexarea, tags, text } = style

type Props = {
	numbers: string[]
	setNumbers: (numbers: string[]) => void
	helperText?: string
}

export const NumbersTextarea = ({ numbers, setNumbers, helperText }: Props) => {
	const [inputValue, setInputValue] = useState<string | undefined>()

	const onCloseHandle = (removedTag: string) => {
		const updatedNumbers = numbers?.filter((tag) => tag !== removedTag)
		setNumbers(updatedNumbers)
	}

	const inputChangeHandle = (e: TOnChangeInput) => {
		const value = e.target.value
		setInputValue(value)
	}

	const onConfirmHandle = () => {
		if (inputValue && !numbers?.includes(inputValue)) {
			setNumbers([...numbers, inputValue])
		}
		setInputValue("")
	}

	return (
		<>
			<div className={modelNumbersTexarea}>
				<div className={tags}>
					{numbers?.map((modelNumber) => (
						<Tag key={modelNumber} closeIcon={<CloseIcon />} onClose={() => onCloseHandle(modelNumber)}>
							{modelNumber}
						</Tag>
					))}
				</div>
				<InputBorderless inputChangeHandle={inputChangeHandle} onConfirmHandle={onConfirmHandle} value={inputValue} />
			</div>
			<p className={text}>{helperText}</p>
		</>
	)
}
