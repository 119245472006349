import { useEffect, useState } from "react"
import DOMPurify from "dompurify"
import { marked } from "marked"

type Props = {
	content: string
	onReferenceClick?: (page: string) => void
}

export const MDtoHTML = ({ content, onReferenceClick }: Props) => {
	const [sanitizedContent, setSanitizedContent] = useState("")

	// Sanitize the markdown-converted HTML
	useEffect(() => {
		// Create a custom renderer for marked
		const renderer = new marked.Renderer()

		// Customize the text renderer to handle [PAGE-XXXX] references
		renderer.text = (token: string | { raw: string; text: string }): string => {
			// Handle both string and object formats
			const textContent = typeof token === "string" ? token : token.text

			try {
				return textContent.replace(/\[PAGE-(\d+)\]/g, (_, page) => {
					return `<button class="referenceButton" data-page="${page}">${page}</button>`
				})
			} catch (error) {
				console.error("Error processing text:", error)
				return textContent // Return original text if replacement fails
			}
		}

		// Set marked options to use our custom renderer
		marked.setOptions({
			renderer: renderer,
			gfm: true,
			breaks: true,
		})

		// Parse the markdown content and sanitize the output
		const parseContent = async () => {
			try {
				const parsedContent = await marked.parse(content)
				const cleaned = DOMPurify.sanitize(parsedContent, {
					ALLOWED_TAGS: [
						"b",
						"i",
						"em",
						"strong",
						"a",
						"p",
						"br",
						"ul",
						"ol",
						"li",
						"h1",
						"h2",
						"h3",
						"h4",
						"h5",
						"h6",
						"blockquote",
						"code",
						"pre",
						"button",
					],
					ALLOWED_ATTR: ["href", "target", "rel", "data-page", "class"],
				})
				setSanitizedContent(cleaned)
			} catch (e) {
				console.error("Error parsing content:", e)
				setSanitizedContent("")
			}
		}

		parseContent()
	}, [content])

	// Add event listener using event delegation
	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			const target = e.target as HTMLElement
			if (target.classList.contains("referenceButton")) {
				const page = target.getAttribute("data-page")
				if (page && onReferenceClick) {
					onReferenceClick(page)
				}
			}
		}

		document.addEventListener("click", handleClick)

		// Cleanup
		return () => {
			document.removeEventListener("click", handleClick)
		}
	}, [onReferenceClick])

	return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
}
