import { ReactNode } from "react"
import { ConfigProvider } from "antd"

type Props = {
	children: ReactNode
}

export const AppProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					fontFamily: "Onest, sans-serif",
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
