import { useCallback, useEffect, useRef } from "react"
import { Select } from "components/ui/select/Select"
import { useProcessings } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import { useGeneralFeedbackStore } from "store/drawer.store"
import type { DefaultOptionType } from "antd/es/select"
import style from "./FileInfoProcessingRuns.module.scss"

const { fileInfoProcessingRuns, text } = style

export const FileInfoProcessingRuns = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const { setGeneralFeedback } = useGeneralFeedbackStore()
	const { data: processingRuns } = useProcessings(drawingId)

	const processingRunRef = useRef<HTMLDivElement | null>(null)

	// Transform processing runs into select options
	const selectOptions: DefaultOptionType[] = Object.entries(processingRuns || {}).map(([label, value]) => ({
		label,
		value: value as number,
	}))

	const updateSearchParams = useCallback(
		(key: string, value: string) => {
			setSearchParams((prev) => {
				const newParams = new URLSearchParams(prev)
				newParams.set(key, value)
				return newParams
			})
		},
		[setSearchParams],
	)

	// Set default processing ID when data is loaded
	useEffect(() => {
		if (selectOptions.length > 0 && !processingId) {
			const latestProcessingId = String(selectOptions[0].value)
			updateSearchParams("processingID", latestProcessingId)
		}
	}, [selectOptions.length, processingId, selectOptions, updateSearchParams])

	// Change processing ID
	const selectChangeHandle = (value: string | number) => {
		setGeneralFeedback("")
		updateSearchParams("processingID", String(value))
	}

	// Change border color if select is open
	const onDropdownVisibleChangeHandle = (open: boolean) => {
		if (processingRunRef.current) {
			if (open) {
				processingRunRef.current.style.border = "1px solid #888DA6"
			} else {
				processingRunRef.current.style.border = "1px solid #DEE0EB"
			}
		}
	}

	return selectOptions.length > 0 ? (
		<div className={fileInfoProcessingRuns} ref={processingRunRef}>
			<span className={text}>Processing run:</span>
			<Select
				variant="borderless"
				value={processingId}
				options={selectOptions}
				onChange={selectChangeHandle}
				onDropdownVisibleChange={onDropdownVisibleChangeHandle}
			/>
		</div>
	) : null
}
