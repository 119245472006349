import { CheckMarkIcon } from "components/ui/icons/generalFeedback/CheckMarkIcon"
import styles from "./FeedbackThankYou.module.scss"

const { feedbackThankYou, titleStyles, textStyles } = styles

type Props = { title: string; text: string }

export const FeedbackThankYou = ({ title, text }: Props) => {
	return (
		<div className={feedbackThankYou}>
			<CheckMarkIcon />
			<span className={titleStyles}>{title}</span>
			<span className={textStyles}>{text}</span>
		</div>
	)
}
