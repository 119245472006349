import { useInstructionsStore } from "store/aiAgent.store"
import { useDeleteInstruction, useUpdateInstructionStatus } from "hooks/AIAgent.hook"
import { useMessage } from "hooks/message.hook"
import type { MenuProps } from "antd"
import type { IInstructionsTable } from "types/table.type"

type Props = { setEditInstructionModalOpen?: () => void; refetchInstructions: () => void }

export const InstructionsTableUtils = ({ setEditInstructionModalOpen, refetchInstructions }: Props) => {
	const { setInstruction } = useInstructionsStore()
	const { mutate: deleteInstruction } = useDeleteInstruction()
	const { success: successMessage, error: errorMessage } = useMessage()
	const { mutate: updateInstructionsStatus } = useUpdateInstructionStatus()

	const onChangeSwitecherHandle = (questionId: number, checked: boolean) => {
		console.log("questionId :>", questionId, "checked :>", checked)
		updateInstructionsStatus({ questionId: questionId, state: checked === true ? "active" : "inactive" })
	}

	const dropdownHandle = (item: IInstructionsTable) => {
		const dropdownItems: MenuProps["items"] = [
			{
				key: "edit",
				label: "Edit",
				onClick: () => {
					setInstruction({
						questionId: item.id,
						question: item.question,
						sections: item.sections,
						prompt: item.prompt,
					})
					if (setEditInstructionModalOpen) setEditInstructionModalOpen()
				},
			},
			{
				type: "divider",
			},
			{
				key: "delete",
				label: "Delete",
				danger: true,
				onClick: () => {
					deleteInstruction(item.id, {
						onSuccess: () => {
							refetchInstructions()
							successMessage("Instruction deleted successfully")
						},
						onError: () => {
							errorMessage("Failed to delete instruction")
						},
					})
				},
			},
		]

		return dropdownItems
	}

	return { dropdownHandle, onChangeSwitecherHandle }
}
