import { Collapse as AntCollapse } from "antd"
import { CollapseBlueProvider } from "components/providers/Collapse.provider"
import { CollapseBlueIcon } from "../icons/collapseIcon/CollapseBlueIcon"
import { CollapseGrayIcon } from "../icons/collapseIcon/CollapseGrayIcon"
import type { SizeType } from "antd/es/config-provider/SizeContext"
import type { CollapseProps } from "antd"

type Props = {
	items: CollapseProps["items"]
	bordered?: boolean
	size?: SizeType
	ghost?: boolean
	defaultActiveKey?: string | number | (string | number)[] | undefined
}

export const Collapse = ({ items, bordered = false, size = "large", ghost = true, defaultActiveKey }: Props) => {
	return (
		<CollapseBlueProvider>
			<AntCollapse
				items={items}
				bordered={bordered}
				defaultActiveKey={defaultActiveKey}
				size={size}
				ghost={ghost}
				expandIcon={({ isActive }) =>
					isActive ? <CollapseBlueIcon rotate={isActive ? 90 : 0} /> : <CollapseGrayIcon rotate={isActive ? 90 : 0} />
				}
			/>
		</CollapseBlueProvider>
	)
}
