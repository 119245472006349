export const ThumbsUpIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.47074 7.21387C3.99547 7.21387 3.75783 7.21387 3.56348 7.2687C3.07487 7.40655 2.69299 7.78843 2.55515 8.27704C2.50031 8.47141 2.50031 8.70899 2.50031 9.18416C2.50031 11.1655 2.50031 13.2243 2.50031 15.2057C2.50031 15.6808 2.50031 15.9184 2.55515 16.1127C2.69299 16.6014 3.07487 16.9832 3.56348 17.1212C3.75783 17.1759 3.99547 17.176 4.47074 17.176C4.94601 17.176 5.18365 17.1759 5.378 17.1212C5.86661 16.9832 6.24847 16.6014 6.38633 16.1127C6.44116 15.9184 6.44116 15.6808 6.44116 15.2057C6.44116 13.2243 6.44116 11.1655 6.44116 9.18416C6.44116 8.70899 6.44116 8.47141 6.38633 8.27704C6.24847 7.78843 5.86661 7.40655 5.378 7.2687C5.18365 7.21387 4.94601 7.21387 4.47074 7.21387Z"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.44071 8.11472C7.64547 6.55704 8.84059 4.99134 10.0552 3.44137C10.4401 2.95029 11.1139 2.70294 11.7188 2.8788C13.068 3.27102 12.7236 5.66387 12.3707 6.93291C12.1091 7.87397 12.4272 8.20039 13.1933 8.20039C14.8685 8.20039 15.6645 8.20039 16.2349 8.54308C16.7564 8.8565 17.1394 9.3565 17.3062 9.94167C17.6539 11.1618 17.5342 13.4862 16.9381 15.1062C16.5355 16.2007 15.3998 16.9533 14.416 17.0625C13.0877 17.2099 11.2336 17.1567 9.83367 17.079C8.54726 17.0076 7.34924 16.4352 6.44071 15.5217"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
