import { Modal } from "../Modal/Modal"
import { ReactNode } from "react"
import { ButtonSecondary } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import style from "./ConfigurationModal.module.scss"

const { configurationModal, bar, button } = style

type Props = {
	children: ReactNode
	setModalClose: () => void
	isModalOpen: boolean
	saveHandle: () => void
	submitButtonText: string
}

export const ConfigurationModal = ({ children, isModalOpen, setModalClose, saveHandle, submitButtonText }: Props) => {
	return (
		<Modal width="100%" isModalOpen={isModalOpen} setModalClose={setModalClose} className="configurationModal">
			<div className={configurationModal}>
				{children}
				<div className={bar}>
					<ButtonSecondary className={button} onClick={setModalClose}>
						Cancel
					</ButtonSecondary>
					<ButtonPrimary className={button} onClick={saveHandle}>
						{submitButtonText}
					</ButtonPrimary>
				</div>
			</div>
		</Modal>
	)
}
