import { useEffect, useState } from "react"
import { Drawer } from "components/ui/drawer/Drawer"
import { useDrawing, useDrawingWithProcessings } from "hooks/drawings.hook"
import { useDrawerStore, useGeneralFeedbackStore } from "store/drawer.store"
import { BandgeStatus } from "components/ui/bandges/BadgeStatus/BadgeStatus"
import { CloseIcon } from "components/ui/icons/close/CloseIcon"
import { H2 } from "components/ui/typography/titles/Titles"
import { TabsDrawer } from "components/ui/tabs/TabsDrawer"
import { TabsProps } from "antd"
import { Documents } from "./layout/Documents/Documents"
import { useSearchParams } from "react-router-dom"
import { FileInfoProcessingRuns } from "./layout/FileInfoProcessingRuns/FileInfoProcessingRuns"
import { FoundItems } from "./layout/FoundItems/FoundItems"
import { Configurations } from "./layout/Configurations/Configurations"
import { Versions } from "./layout/Versions/Versions"
import { useChatStore } from "store/aiAgent.store"
import style from "./FileInfo.module.scss"

const { fileInfo, wrapper, header, title, content, status, closeIcon, closeButton, statusAndRuns, drawingStatusStyle } =
	style

export const FileInfo = () => {
	const [tabActiveKey, setTabActiveKey] = useState("1")

	const { isDrawerOpen, setDrawerClose, setDrawerOpen } = useDrawerStore()
	const { setGeneralFeedback } = useGeneralFeedbackStore()
	const { clearChatHistory, closeChat } = useChatStore()

	const [searchParams, setSearchParams] = useSearchParams()
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const { data: drawing } = useDrawing(drawingId)
	const { data: drawingWithProcessings } = useDrawingWithProcessings(drawingId, processingId)

	const statusDrawingWithProcessing = drawingWithProcessings?.status
	const drawingStatus = drawing?.status
	const finalDrawingStatus = drawingStatus !== "uploaded" ? statusDrawingWithProcessing : drawingStatus // Status of current drawing

	const tabsItems: TabsProps["items"] = [
		{
			key: "1",
			label: "Documents",
			children: <Documents drawingStatus={drawingStatus} drawingOwner={drawing?.owner} />,
		},
		{
			key: "2",
			label: "Found items",
			children: <FoundItems drawingStatus={finalDrawingStatus} />,
			disabled: finalDrawingStatus === "processed" ? false : true,
		},
		{
			key: "3",
			label: "Configurations",
			children: <Configurations drawingStatus={finalDrawingStatus} />,
			disabled: finalDrawingStatus === "processed" ? false : true,
		},
		{
			key: "4",
			label: "Versions",
			children: <Versions drawingStatus={finalDrawingStatus} />,
			disabled: finalDrawingStatus === "processed" || finalDrawingStatus === "failed" ? false : true,
		},
	]

	// Fetch documents and drawer info
	useEffect(() => {
		if (drawingId) setDrawerOpen()
	}, [drawingId, setDrawerOpen])

	// Set active tab to first if drawing status is processing or failed or in_queue
	useEffect(() => {
		if (finalDrawingStatus === "processing" || finalDrawingStatus === "failed" || finalDrawingStatus === "in_queue") {
			setTabActiveKey("1")
		}
	}, [finalDrawingStatus])

	// Rreset drawer ID and close the drower
	const closeDrawerHandle = () => {
		setDrawerClose() // Close the drawer
		setSearchParams({}) // Reset all Search Params
		setTabActiveKey("1") // Set tab to "Documents" when drawer is closed
		setGeneralFeedback("") // Reset general feedback
		clearChatHistory() // Clear chat history
		closeChat() // Close chat
		document.title = "Stevens | AutoScan" // Reset document title
	}

	// Set the tavs active keys
	const onChangeTabsHandle = (activeKey: string) => {
		setTabActiveKey(activeKey)
	}

	return (
		<Drawer width={1100} open={isDrawerOpen} onClose={closeDrawerHandle}>
			<div className={fileInfo}>
				<div className={wrapper}>
					<div className={header}>
						<div className={status}>
							<div className={statusAndRuns}>
								<div className={drawingStatusStyle}>
									<BandgeStatus type={finalDrawingStatus} />
								</div>
								{finalDrawingStatus !== "uploaded" ? <FileInfoProcessingRuns /> : null}
							</div>
							<button className={closeButton} onClick={closeDrawerHandle}>
								<CloseIcon className={closeIcon} />
							</button>
						</div>
						<H2 className={title}>{drawing?.name}</H2>
					</div>
					<div className={`DrawerTabsContent ${content}`}>
						<TabsDrawer items={tabsItems} onChangeHandle={onChangeTabsHandle} activeKey={tabActiveKey} />
					</div>
				</div>
			</div>
		</Drawer>
	)
}
