import { create } from "zustand"
import { ITableState, TPagination } from "types/table.type"

export const useTableStore = create<ITableState>((set) => ({
	updatedTableData: [],
	setUpdatedTableData: (data) => set(() => ({ updatedTableData: data })),
}))

export const useFillesTableStore = create<TPagination>((set) => ({
	tableParams: {
		pagination: {
			current: Number(sessionStorage.getItem("pagination")) || 1,
		},
		filter: {
			author: localStorage.getItem("owner") ?? "all",
			fileName: "",
		},
	},
	setCurrentPage: (current: number) => {
		sessionStorage.setItem("pagination", current.toString())
		set((state) => ({
			tableParams: {
				...state.tableParams,
				pagination: {
					...state.tableParams.pagination,
					current,
				},
			},
		}))
	},
	setFilter: (filter) => {
		set((state) => ({
			tableParams: {
				...state.tableParams,
				filter: {
					...state.tableParams.filter,
					...filter,
				},
			},
		}))
	},
	resetSessionStoragePagination: () => {
		sessionStorage.setItem("pagination", "1")
		set((state) => ({
			tableParams: {
				...state.tableParams,
				pagination: {
					current: 1,
				},
			},
		}))
	},
}))
