import { CloseIcon } from "components/ui/icons/uploadbar/CloseIcon"
import { AIAgentIcon } from "components/ui/icons/iaAgent/AIAgentIcon"
import { Chat } from "./layout/Chat/Chat"
import { TrashIcon } from "components/ui/icons/iaAgent/TrashIcon"
import { useChatStore } from "store/aiAgent.store"
import { useMessage } from "hooks/message.hook"
import { Tooltip } from "components/ui/tooltip/Tooltip"
import style from "./AIAgent.module.scss"

const { aiAgent, header, title, logo, buttonsBlock, clearChatButton, closeChatButton } = style

export const AIAgent = () => {
	const { clearChatHistory, closeChat } = useChatStore()
	const { success: successMessage } = useMessage()

	const clearChatHistoryHandle = () => {
		clearChatHistory()
		successMessage("Chat history cleared")
	}

	return (
		<div className={aiAgent}>
			<div className={header}>
				<div className={logo}>
					<AIAgentIcon />
					<span className={title}>AI Agent</span>
				</div>
				<div className={buttonsBlock}>
					<Tooltip placement="bottom" text="Clear chat history">
						<button className={clearChatButton} onClick={() => clearChatHistoryHandle()} data-testid="clearChatButton">
							<TrashIcon />
						</button>
					</Tooltip>
					<button className={closeChatButton} onClick={() => closeChat()} data-testid="closeChatButton">
						<CloseIcon />
					</button>
				</div>
			</div>
			<Chat />
		</div>
	)
}
